import { useCallback } from 'react';

import { InviteUsersType } from '../../../../../../types';
import { ItemIndexSidebarTeamItem } from '../../../../components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarTeam';

import { useSelectedUsers } from '../useSelectedUsers';

import { raiseError } from '../../../../../../utils/raiseError';

interface InviteUsersModalButtonOptions<InviteUsersResponse> {
  onInviteUsers: ({
    uuid,
    userIds
  }: InviteUsersType) => Promise<InviteUsersResponse>;
  item: ItemIndexSidebarTeamItem;
}

function useInviteUsersModalButton<InviteUsersResponse>({
  onInviteUsers,
  item
}: InviteUsersModalButtonOptions<InviteUsersResponse>) {
  const {
    selectedUsers,
    selectedUserIds,
    handleSelectUser,
    handleDeselectUser,
    handleDeselectAllUsers
  } = useSelectedUsers();

  const handleInviteUsers = useCallback(
    () =>
      onInviteUsers({
        uuid: item.uuid,
        userIds: selectedUserIds
      })
        .catch((reason) => raiseError(reason))
        .then(() => Promise.resolve()),
    [onInviteUsers, selectedUserIds, item.uuid]
  );

  return {
    handleDeselectAllUsers,
    handleDeselectUser,
    handleInviteUsers,
    handleSelectUser,
    selectedUserIds,
    selectedUsers
  };
}

export default useInviteUsersModalButton;
