import { useCallback } from 'react';
import toNumber from 'lodash/toNumber';

import { TaskAmount, TaskFields, TaskUUID } from '../../../../../tasksTypes';

import {
  UPDATE_TASK_AMOUNT_QUERY,
  UpdateTaskAmountQueryResponse
} from '../../../../../queries/updateTaskAmount.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useUpdateTaskAmount } from '../../../../../hooks/useUpdateTaskAmount';

interface UpdateTaskAmountFormData {
  amount?: string;
}

interface UpdateTaskAmountFormOptions {
  amount: TaskAmount;
  uuid: TaskUUID;
}

function useUpdateTaskAmountForm({
  amount,
  uuid
}: UpdateTaskAmountFormOptions) {
  const { handleSubmitReactHookForm, register, setValue } =
    useReactHookForm<UpdateTaskAmountFormData>({
      defaultValues: {
        amount: amount.toString()
      },
      isModalForm: true
    });

  const handleInitUpdateTaskAmountForm = useCallback(
    () => setValue(TaskFields.AMOUNT as 'amount', amount.toString()),
    [amount, setValue]
  );

  const {
    updateTaskAmount,
    updateTaskAmountLoading,
    updateTaskAmountErrorMessage,
    updateTaskAmountReset
  } = useUpdateTaskAmount<UpdateTaskAmountQueryResponse>({
    query: UPDATE_TASK_AMOUNT_QUERY
  });

  return {
    updateTaskAmount,
    updateTaskAmountLoading,
    updateTaskAmountErrorMessage,
    updateTaskAmountReset,
    handleInitUpdateTaskAmountForm,
    handleUpdateTaskAmount: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateTaskAmountFormData) =>
        updateTaskAmount({
          uuid,
          amount: toNumber(data.amount)
        })
    }),
    registerAmount: register(TaskFields.AMOUNT)
  };
}

export default useUpdateTaskAmountForm;
