import { I18nText, IsDisabled } from '../../../../../types';
import { ProjectImplementationDate, ProjectUUID } from '../../../projectsTypes';

import { useUpdateProjectImplementationDateForm } from '../../forms/UpdateProjectImplementationDateForm/hooks/useUpdateProjectImplementationDateForm';

import { UpdateProjectImplementationDateForm } from '../../forms/UpdateProjectImplementationDateForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { projectsKeys, words } from '../../../../../locales/keys';

interface UpdateProjectImplementationDateModalButtonProps {
  disabled?: IsDisabled;
  implementationDate: ProjectImplementationDate;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: ProjectUUID;
}

const UPDATE_PROJECT_IMPLEMENTATION_DATE_FORM =
  'update-project-implementation-date-form';

function UpdateProjectImplementationDateModalButton({
  disabled,
  implementationDate,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateProjectImplementationDateModalButtonProps) {
  const {
    handleInitUpdateProjectImplementationDateForm,
    handleUpdateProjectImplementationDate,
    implementationDateValidationError,
    isDirty,
    registerProjectImplementationDate,
    updateProjectImplementationDateErrorMessage,
    updateProjectImplementationDateLoading,
    updateProjectImplementationDateReset
  } = useUpdateProjectImplementationDateForm({
    implementationDate,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<DateTimeHelper date={implementationDate} />}
      className="underline"
      disabled={disabled}
      form={UPDATE_PROJECT_IMPLEMENTATION_DATE_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || projectsKeys.setDueDate}
      isLoading={updateProjectImplementationDateLoading}
      onOpen={handleInitUpdateProjectImplementationDateForm}
      onClose={updateProjectImplementationDateReset}
      onSubmit={handleUpdateProjectImplementationDate}
      submitDisabled={!isDirty}
    >
      <UpdateProjectImplementationDateForm
        form={UPDATE_PROJECT_IMPLEMENTATION_DATE_FORM}
        i18nPlaceholder={i18nPlaceholder || projectsKeys.addName}
        implementationDateValidationError={implementationDateValidationError}
        isLoading={updateProjectImplementationDateLoading}
        registerProjectImplementationDate={registerProjectImplementationDate}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectImplementationDateErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectImplementationDateModalButton;
