import { gql } from 'graphql-request';
import {
  ProjectID,
  ProjectTagsLocalizedName,
  ProjectTagsUUID,
  ProjectUUID
} from '../projectsTypes';

export interface UpdateProjectTagIdsQueryResponse {
  id: ProjectID;
  uuid: ProjectUUID;
  tags: {
    uuid: ProjectTagsUUID;
    localizedName: ProjectTagsLocalizedName;
  };
}

export const UPDATE_PROJECT_TAG_IDS_QUERY = gql`
  mutation UpdateProjectTagIds($uuid: ID!, $tagIds: [ID!]!) {
    updateProjectTagIds(input: { uuid: $uuid, tagIds: $tagIds }) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        tags {
          uuid
          localizedName
        }
      }
    }
  }
`;
