import React, { useMemo } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import {
  AssignUserToItemType,
  ErrorMessage,
  InviteUsersType,
  IsLoading,
  RemoveItemMembersType
} from '../../../../../../../types';

import { ItemIndexSidebarTeamMembersList } from '../ItemIndexSidebarTeamMembersList';
import { InviteUsersModalButton } from '../../../../../modalButtons/InviteUsersModalButton';

import { Translate } from '../../../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';

import { words } from '../../../../../../../locales/keys';

import {
  ItemIndexSidebarTeamItem,
  ItemIndexSidebarTeamItemMembers,
  ItemIndexSidebarTeamItemUsers
} from './ItemIndexSidebarTeam.types';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';
import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';

interface ItemIndexSidebarTeamProps<InviteUsersResponse> {
  inviteUsersErrorMessage: ErrorMessage;
  assignUserErrorMessage: ErrorMessage;
  removeMembersErrorMessage: ErrorMessage;
  isMemberRemoving: IsLoading;
  isUserAssigning: IsLoading;
  isUsersInviting: IsLoading;
  item: ItemIndexSidebarTeamItem;
  itemMembers: ItemIndexSidebarTeamItemMembers;
  onAssignUser: ({ uuid, userId }: AssignUserToItemType) => void;
  onInviteUsers: ({
    uuid,
    userIds
  }: InviteUsersType) => Promise<InviteUsersResponse>;
  onRemoveMember: ({ uuid, userIds }: RemoveItemMembersType) => void;
  scope: 'projects' | 'tasks';
}

function ItemIndexSidebarTeam<InviteUsersResponse>({
  inviteUsersErrorMessage,
  assignUserErrorMessage,
  removeMembersErrorMessage,
  isMemberRemoving,
  isUserAssigning,
  isUsersInviting,
  item,
  itemMembers,
  onAssignUser,
  onInviteUsers,
  onRemoveMember,
  scope
}: ItemIndexSidebarTeamProps<InviteUsersResponse>) {
  const actualItem = useMemo<ItemIndexSidebarTeamItem>(
    () => ({
      ...item,
      user: {
        ...item.user,
        trackedTime: find(
          itemMembers,
          (itemMember) => itemMember.user?.uuid === item.user?.uuid
        )?.trackedTime
      }
    }),
    [item, itemMembers]
  );

  const actualMembers = useMemo<ItemIndexSidebarTeamItemUsers>(
    () =>
      (item.user
        ? filter(
            item.members || [],
            (member) => member.nanoId !== item.user.nanoId
          )
        : item.members || []
      ).map((actualMember) => ({
        ...actualMember,
        trackedTime: find(
          itemMembers,
          (itemMember) => itemMember.user?.uuid === actualMember.uuid
        )?.trackedTime
      })),
    [item, itemMembers]
  );

  const inviteUsersModalButtonPermission =
    scope === 'tasks'
      ? TasksPermissions.READ_TASK_INVITE_USER_BUTTON
      : ProjectsPermissions.READ_PROJECT_INVITE_USER_BUTTON;

  return (
    <div className="p-4">
      <div className="flex justify-between mb-3">
        <div>
          <h6 className="font-light uppercase">
            <Translate id={`models.${scope}.team`} />
          </h6>
        </div>

        {isEmpty(actualMembers) ? null : (
          <div className="flex space-x-1">
            <span>{size(actualMembers)}</span>
            <span>
              <Translate id={words.members} />
            </span>
          </div>
        )}
      </div>

      <CheckPermissions action={inviteUsersModalButtonPermission}>
        <div className="-mx-2 mt-2 space-y-1">
          <InviteUsersModalButton
            errorMessage={inviteUsersErrorMessage}
            isLoading={isUsersInviting}
            onInviteUsers={onInviteUsers}
            item={item}
            icon={IconsEnum.PLUS}
            i18nText={words.invite_user}
          />
        </div>
      </CheckPermissions>

      <ItemIndexSidebarTeamMembersList
        isMemberRemoving={isMemberRemoving}
        isUserAssigning={isUserAssigning}
        item={actualItem}
        members={actualMembers}
        onAssignUser={onAssignUser}
        onRemoveMember={onRemoveMember}
        scope={scope}
      />

      <AlertMessage
        message={assignUserErrorMessage || removeMembersErrorMessage}
        addClassName="mt-4"
      />
    </div>
  );
}

export default ItemIndexSidebarTeam;
