import React from 'react';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ItemIndexSidebarTeamItem } from '../ItemIndexSidebarTeam';

import { UserRoleAvatar } from '../../../../../helpers/UserRoleAvatar';

import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';

interface ItemIndexSidebarTeamTaskCreatorProps {
  item: ItemIndexSidebarTeamItem;
  scope: 'projects' | 'tasks';
}

function ItemIndexSidebarTeamTaskCreator({
  item,
  scope
}: ItemIndexSidebarTeamTaskCreatorProps) {
  const currentUser = useCurrentUser();

  const showTrackedTime =
    (scope === 'projects' &&
      currentUser.hasPermissions(
        ProjectsPermissions.READ_PROJECT_MEMBER_TRACKED_TIME
      )) ||
    (scope === 'tasks' &&
      currentUser.hasPermissions(
        TasksPermissions.READ_TASK_MEMBER_TRACKED_TIME
      ));

  return (
    <li className="group p-2 -mx-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-850">
      <div className="flex items-center space-x-4">
        <UserRoleAvatar user={item.user} />
        {showTrackedTime ? (
          <div className="text-sm">{item.user?.trackedTime}</div>
        ) : null}
      </div>
    </li>
  );
}

export default ItemIndexSidebarTeamTaskCreator;
