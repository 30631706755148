import { DateType, UUID } from '../../../../../types';

export interface ItemSidebarDatesItem {
  uuid: UUID;
  startDate?: DateType;
  implementationDate: DateType;
  implementedAt?: DateType;
  setDate: DateType;
}

export enum ItemSidebarDatesScopeEnum {
  PROJECTS = 'projects',
  TASKS = 'tasks'
}
