import {
  ErrorMessage,
  I18nText,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';

import { ProjectsPermissions } from '../../../projectsConstants';

interface UpdateProjectImplementationDateFormProps {
  form: string;
  i18nPlaceholder?: I18nText;
  implementationDateValidationError?: ErrorMessage;
  isLoading?: IsLoading;
  registerProjectImplementationDate: RegisterFormFieldType<HTMLInputElement>;
}

function UpdateProjectImplementationDateForm({
  form,
  i18nPlaceholder,
  implementationDateValidationError,
  isLoading,
  registerProjectImplementationDate
}: UpdateProjectImplementationDateFormProps) {
  const currentUser = useCurrentUser();

  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div>
          <div>
            <InputField
              disabled={isLoading}
              error={implementationDateValidationError}
              i18nPlaceholder={i18nPlaceholder}
              labelClassName="font-light uppercase"
              name={registerProjectImplementationDate.name}
              onChange={registerProjectImplementationDate.onChange}
              ref={registerProjectImplementationDate.ref}
              showErrorIcon={false}
              type={
                currentUser.hasPermissions(
                  ProjectsPermissions.CHANGE_PROJECT_IMPLEMENTATION_TIME
                )
                  ? 'datetime-local'
                  : 'date'
              }
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default UpdateProjectImplementationDateForm;
