import {
  CreateTaskGqlQuery,
  CreateTaskGqlStatus,
  FetchTasksCacheKey,
  TaskAmount,
  TaskDescription,
  TaskDpi,
  TaskFileAttachmentIds,
  TaskFileExtension,
  TaskGqlError,
  TaskHeight,
  TaskImplementationDate,
  TaskName,
  TaskUUID,
  TaskNanoID,
  TaskWidth
} from '../../tasksTypes';

import { ProjectNanoID } from '../../../projects/projectsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateTaskOptions {
  query: CreateTaskGqlQuery;
  cacheKeys?: FetchTasksCacheKey[];
}

interface CreateTaskRecordResponse {
  uuid: TaskUUID;
}

interface CreateTaskResponse {
  createTask: {
    status: CreateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: CreateTaskRecordResponse;
    errors: CreateTaskError;
  };
}

interface CreateTaskInput {
  projectNanoId: ProjectNanoID;
  name: TaskName;
  description?: TaskDescription | null;
  implementationDate?: TaskImplementationDate | null;
  amount?: TaskAmount | null;
  width?: TaskWidth | null;
  height?: TaskHeight | null;
  dpi?: TaskDpi | null;
  fileExtension?: TaskFileExtension | null;
  fileAttachmentIds?: TaskFileAttachmentIds | null;
}

interface CreateTaskError {
  projectId: TaskGqlError;
  name: TaskGqlError;
  description: TaskGqlError;
  implementationDate: TaskGqlError;
  amount: TaskGqlError;
  width: TaskGqlError;
  height: TaskGqlError;
  dpi: TaskGqlError;
  fileExtension: TaskGqlError;
  fileAttachmentIds: TaskGqlError;
  fullMessages: TaskGqlError;
}

const action = 'createTaskWithProjectNanoId';

function useCreateTaskWithProjectNanoId({
  query,
  cacheKeys
}: CreateTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateTaskInput,
    CreateTaskResponse,
    CreateTaskError,
    CreateTaskRecordResponse
  >({ action, cacheKeys, query });

  return {
    createTaskData: createQueryData,
    createTaskError: createQueryError,
    createTaskLoading: createQueryLoading,
    createTaskErrorMessage: createQueryErrorMessage,
    createTask: createQuery,
    createTaskReset: createQueryReset
  };
}

export default useCreateTaskWithProjectNanoId;
