import React, { Fragment, useCallback } from 'react';
import cl from 'classnames';

import { I18nText, UUID } from '../../../../../../../../types';

import { IconsEnum } from '../../../../../../../../assets/icons/types';

import { ItemIndexSidebarInfoItem } from '../../ItemIndexSidebarInfo';

import { PureIconButtonHelper } from '../../../../../../../../helpers/buttons/PureIconButtonHelper';
import { PureTooltipIconButtonHelper } from '../../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../../../../helpers/tooltips/tooltipsConstants';

interface ToggleItemNotificationsEnabledButtonProps {
  disabled?: boolean;
  item: ItemIndexSidebarInfoItem;
  onToggleItemNotificationsEnabled: ({ uuid }: { uuid: UUID }) => void;
  isButtonClassName?: boolean;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
}

function ToggleItemNotificationsEnabledButton({
  disabled,
  item,
  onToggleItemNotificationsEnabled,
  isButtonClassName = true,
  tooltipI18nText,
  tooltipSingleton
}: ToggleItemNotificationsEnabledButtonProps) {
  const handleToggleItemNotificationsEnabled = useCallback(
    () => onToggleItemNotificationsEnabled({ uuid: item.uuid }),
    [onToggleItemNotificationsEnabled, item.uuid]
  );

  return (
    <Fragment>
      {isButtonClassName ? (
        <PureIconButtonHelper
          className={cl(
            'focus:ring-offset-0 p-1.5 rounded-md',
            item.notificationsEnabled
              ? 'bg-blue-500 bg-opacity-20 text-blue-500 dark:text-blue-400 hover:bg-blue-600 hover:text-blue-600 hover:bg-opacity-20 dark:hover:bg-blue-600 dark:hover:text-blue-300 dark:hover:bg-opacity-20'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-800 hover:text-gray-800 dark:hover:text-gray-200'
          )}
          disabled={disabled}
          icon={
            item.notificationsEnabled
              ? IconsEnum.BLUE_BELL
              : IconsEnum.CROSSED_BELL
          }
          onClick={handleToggleItemNotificationsEnabled}
        />
      ) : (
        <PureTooltipIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 hidden sm:flex"
          disabled={disabled}
          icon={
            item.notificationsEnabled
              ? IconsEnum.BELL_OUTLINE
              : IconsEnum.CROSSED_BELL
          }
          onClick={handleToggleItemNotificationsEnabled}
          tooltipI18nText={tooltipI18nText}
          tooltipPlacement={TooltipPlacement.BOTTOM}
          tooltipSingleton={tooltipSingleton}
        />
      )}
    </Fragment>
  );
}

export default ToggleItemNotificationsEnabledButton;
