import { useCallback } from 'react';
import toNumber from 'lodash/toNumber';

import {
  ProjectEstimateCost,
  ProjectFields,
  ProjectUUID
} from '../../../../../projectsTypes';

import {
  UPDATE_PROJECT_ESTIMATE_COST_QUERY,
  UpdateProjectEstimateCostQueryResponse
} from '../../../../../queries/updateProjectEstimateCost.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useUpdateProjectEstimateCost } from '../../../../../hooks/useUpdateProjectEstimateCost';

interface UpdateProjectEstimateCostFormData {
  estimateCost: string;
}

interface UpdateProjectEstimateCostFormOptions {
  estimateCost: ProjectEstimateCost;
  uuid: ProjectUUID;
}

function useUpdateProjectEstimateCostForm({
  estimateCost,
  uuid
}: UpdateProjectEstimateCostFormOptions) {
  const { handleSubmitReactHookForm, register, setValue } =
    useReactHookForm<UpdateProjectEstimateCostFormData>({
      defaultValues: {
        estimateCost: estimateCost.toString()
      },
      isModalForm: true
    });

  const handleInitUpdateProjectEstimateCostForm = useCallback(
    () =>
      setValue(
        ProjectFields.ESTIMATE_COST as 'estimateCost',
        estimateCost.toString()
      ),
    [estimateCost, setValue]
  );

  const {
    updateProjectEstimateCost,
    updateProjectEstimateCostLoading,
    updateProjectEstimateCostErrorMessage,
    updateProjectEstimateCostReset
  } = useUpdateProjectEstimateCost<UpdateProjectEstimateCostQueryResponse>({
    query: UPDATE_PROJECT_ESTIMATE_COST_QUERY
  });

  return {
    updateProjectEstimateCost,
    updateProjectEstimateCostLoading,
    updateProjectEstimateCostErrorMessage,
    updateProjectEstimateCostReset,
    handleInitUpdateProjectEstimateCostForm,
    handleUpdateProjectEstimateCost: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateProjectEstimateCostFormData) =>
        updateProjectEstimateCost({
          uuid,
          estimateCost: toNumber(data.estimateCost)
        })
    }),
    registerEstimateCost: register(ProjectFields.ESTIMATE_COST)
  };
}

export default useUpdateProjectEstimateCostForm;
