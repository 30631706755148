import React from 'react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import cl from 'classnames';

import { UserRoleAvatar } from '../../../../../helpers/UserRoleAvatar';
import { UserAvatarLink } from '../../../../../helpers/UserAvatarLink';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../../../helpers/Translate';

import { ItemIndexSidebarClientDropdownMenu } from './components/ItemIndexSidebarClientDropdownMenu';

import { ItemIndexSidebarClientItem } from './ItemIndexSidebarClient.types';

import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';

interface ItemIndexSidebarClientProps {
  item: ItemIndexSidebarClientItem;
}

function ItemIndexSidebarClient({ item }: ItemIndexSidebarClientProps) {
  if (item.project && !item.project.user) {
    return null;
  } else if (!item.user) {
    return null;
  }

  const actualMembers = item.project
    ? filter(
        item.members || [],
        (member) => member.nanoId === item.project.user.nanoId
      )
    : item.members || [];

  return (
    <div className="p-4">
      <div className="flex justify-between mb-3">
        <div>
          <h6 className="font-light uppercase">
            <Translate id="words.client" />
          </h6>
        </div>
      </div>
      <ul className="-my-2">
        {isEmpty(item.project) ? (
          <li className="group p-2 -mx-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-850">
            <div className="flex items-center space-x-4">
              <UserRoleAvatar user={item.user} />
              <CheckPermissions
                action={ProjectsPermissions.READ_PROJECT_CLIENT_MENU_DROPDOWN}
              >
                <ItemIndexSidebarClientDropdownMenu
                  userNanoId={item.user.nanoId}
                />
              </CheckPermissions>
            </div>
          </li>
        ) : (
          <li className="group p-2 -mx-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-850">
            <div className="flex items-center space-x-4">
              <div
                className={cl('flex-shrink-0', {
                  'opacity-50': !actualMembers.length
                })}
              >
                <UserAvatarLink avatarType="main" user={item.project.user} />
              </div>
              <div
                className={cl('flex-1 min-w-0', {
                  'opacity-50': !actualMembers.length
                })}
              >
                <p className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
                  {item.project.user.fullName}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                  {item.project.user.financeRoleName}
                </p>
              </div>
              <CheckPermissions
                action={TasksPermissions.READ_TASK_CLIENT_MENU_DROPDOWN}
              >
                <ItemIndexSidebarClientDropdownMenu
                  userNanoId={item.project.user.nanoId}
                />
              </CheckPermissions>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

export default ItemIndexSidebarClient;
