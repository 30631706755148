import { gql } from 'graphql-request';

import { TaskPrepayment, TaskUUID } from '../tasksTypes';

export interface UpdateTaskPrepaymentQueryResponse {
  uuid: TaskUUID;
  prepayment: TaskPrepayment;
}

export const UPDATE_TASK_PREPAYMENT_QUERY = gql`
  mutation UpdateTaskPrepayment($uuid: ID!, $prepayment: Float!) {
    updateTaskPrepayment(input: { uuid: $uuid, prepayment: $prepayment }) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        prepayment
      }
      errors {
        prepayment
        fullMessages
      }
    }
  }
`;
