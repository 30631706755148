import { I18nText, IsDisabled } from '../../../../../types';
import { TaskImplementationDate, TaskUUID } from '../../../tasksTypes';

import { useUpdateTaskImplementationDateForm } from '../../forms/UpdateTaskImplementationDateForm/hooks/useUpdateTaskImplementationDateForm';

import { UpdateTaskImplementationDateForm } from '../../forms/UpdateTaskImplementationDateForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { tasksKeys, words } from '../../../../../locales/keys';

interface UpdateTaskImplementationDateModalButtonProps {
  disabled?: IsDisabled;
  implementationDate: TaskImplementationDate;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: TaskUUID;
}

const UPDATE_TASK_IMPLEMENTATION_DATE_FORM =
  'update-task-implementation-date-form';

function UpdateTaskImplementationDateModalButton({
  disabled,
  implementationDate,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateTaskImplementationDateModalButtonProps) {
  const {
    handleInitUpdateTaskImplementationDateForm,
    handleUpdateTaskImplementationDate,
    implementationDateValidationError,
    isDirty,
    registerTaskImplementationDate,
    updateTaskImplementationDateErrorMessage,
    updateTaskImplementationDateLoading,
    updateTaskImplementationDateReset
  } = useUpdateTaskImplementationDateForm({
    implementationDate,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<DateTimeHelper date={implementationDate} />}
      className="underline"
      disabled={disabled}
      form={UPDATE_TASK_IMPLEMENTATION_DATE_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || tasksKeys.setDueDate}
      isLoading={updateTaskImplementationDateLoading}
      onOpen={handleInitUpdateTaskImplementationDateForm}
      onClose={updateTaskImplementationDateReset}
      onSubmit={handleUpdateTaskImplementationDate}
      submitDisabled={!isDirty}
    >
      <UpdateTaskImplementationDateForm
        form={UPDATE_TASK_IMPLEMENTATION_DATE_FORM}
        i18nPlaceholder={i18nPlaceholder || tasksKeys.addName}
        implementationDateValidationError={implementationDateValidationError}
        isLoading={updateTaskImplementationDateLoading}
        registerTaskImplementationDate={registerTaskImplementationDate}
      />

      <div className="px-4">
        <AlertMessage message={updateTaskImplementationDateErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateTaskImplementationDateModalButton;
