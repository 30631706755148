import { gql } from 'graphql-request';

import { ProjectCreditCommission, ProjectUUID } from '../projectsTypes';

export interface UpdateProjectCreditCommissionQueryResponse {
  uuid: ProjectUUID;
  creditCommission: ProjectCreditCommission;
}

export const UPDATE_PROJECT_CREDIT_COMMISSION_QUERY = gql`
  mutation UpdateProjectCreditCommission(
    $uuid: ID!
    $creditCommission: Float!
  ) {
    updateProjectCreditCommission(
      input: { uuid: $uuid, creditCommission: $creditCommission }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        creditCommission
      }
      errors {
        creditCommission
        fullMessages
      }
    }
  }
`;
