import { useItemDueDateValidationRules } from '../../../../../../common/forms/hooks/useItemDueDateValidationRules';

import { TasksPermissions } from '../../../../../tasksConstants';

function useUpdateTaskImplementationDateValidationRules() {
  const implementationDateRules = useItemDueDateValidationRules({
    action: TasksPermissions.CHANGE_TASK_IMPLEMENTATION_TIME
  });

  return {
    implementationDateRules
  };
}

export default useUpdateTaskImplementationDateValidationRules;
