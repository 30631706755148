import {
  ProjectGqlError,
  ProjectImplementationDate,
  ProjectNanoID,
  ProjectUUID,
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { ProjectCache } from '../../ProjectCache';

interface UpdateProjectImplementationDateOptions {
  query: UpdateProjectGqlQuery;
}

export interface UpdateProjectImplementationDateResponse<
  UpdateProjectRecordType
> {
  updateProjectImplementationDate: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: UpdateProjectRecordType;
    errors: UpdateProjectImplementationDateError;
  };
}

export interface UpdateProjectImplementationDateInput {
  uuid: ProjectUUID;
  implementationDate?: ProjectImplementationDate;
}

export interface UpdateProjectImplementationDateError {
  implementationDate: ProjectGqlError;
  fullMessages: ProjectGqlError;
}

const action = 'updateProjectImplementationDate';
const scope = ProjectCache.showCacheKey();
const pluralScope = ProjectCache.indexCacheKey();

function useUpdateProjectImplementationDate<UpdateProjectRecordType>({
  query
}: UpdateProjectImplementationDateOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateProjectImplementationDateInput,
    UpdateProjectImplementationDateResponse<UpdateProjectRecordType>,
    UpdateProjectImplementationDateError,
    UpdateProjectRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateProjectImplementationDateData: updateQueryData,
    updateProjectImplementationDateError: updateQueryError,
    updateProjectImplementationDateLoading: updateQueryLoading,
    updateProjectImplementationDateErrorMessage: updateQueryErrorMessage,
    updateProjectImplementationDate: updateQuery,
    updateProjectImplementationDateReset: updateQueryReset
  };
}

export default useUpdateProjectImplementationDate;
