import { gql } from 'graphql-request';

import {
  TaskID,
  TaskImplementationDate,
  TaskNanoID,
  TaskUUID
} from '../tasksTypes';

export interface UpdateTaskImplementationDateQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  implementationDate: TaskImplementationDate;
}

export const UPDATE_TASK_IMPLEMENTATION_DATE_QUERY = gql`
  mutation UpdateTaskImplementationDate(
    $uuid: ID!
    $implementationDate: DateTime!
  ) {
    updateTaskImplementationDate(
      input: { uuid: $uuid, implementationDate: $implementationDate }
    ) {
      errors {
        implementationDate
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        implementationDate
      }
    }
  }
`;
