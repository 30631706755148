import {
  TaskAmount,
  TaskGqlError,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateTaskAmountOptions {
  query: UpdateTaskGqlQuery;
}

export interface UpdateTaskAmountResponse<UpdateTaskAmountRecordType> {
  updateTaskAmount: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: UpdateTaskAmountRecordType;
    errors: UpdateTaskAmountError;
  };
}

export interface UpdateTaskAmountInput {
  uuid: TaskUUID;
  amount: TaskAmount;
}

export interface UpdateTaskAmountError {
  amount: TaskGqlError;
  fullMessages: TaskGqlError;
}

const action = 'updateTaskAmount';

const scope = 'task';

const pluralScope = 'tasks';

function useUpdateTaskAmount<UpdateTaskAmountRecordType>({
  query
}: UpdateTaskAmountOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateTaskAmountInput,
    UpdateTaskAmountResponse<UpdateTaskAmountRecordType>,
    UpdateTaskAmountError,
    UpdateTaskAmountRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateTaskAmountData: updateQueryData,
    updateTaskAmountError: updateQueryError,
    updateTaskAmountLoading: updateQueryLoading,
    updateTaskAmountErrorMessage: updateQueryErrorMessage,
    updateTaskAmount: updateQuery,
    updateTaskAmountReset: updateQueryReset
  };
}

export default useUpdateTaskAmount;
