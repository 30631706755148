import isEmpty from 'lodash/isEmpty';

import {
  AssignUserToItemType,
  IsLoading,
  RemoveItemMembersType
} from '../../../../../../../types';

import {
  ItemIndexSidebarTeamItem,
  ItemIndexSidebarTeamItemUsers
} from '../ItemIndexSidebarTeam';

import { ItemIndexSidebarTeamMember } from '../ItemIndexSidebarTeamMember';
import { ItemIndexSidebarTeamTaskCreator } from '../ItemIndexSidebarTeamTaskCreator';

interface ItemIndexSidebarTeamMemberProps {
  isMemberRemoving: IsLoading;
  isUserAssigning: IsLoading;
  item: ItemIndexSidebarTeamItem;
  members: ItemIndexSidebarTeamItemUsers;
  onAssignUser: ({ uuid, userId }: AssignUserToItemType) => void;
  onRemoveMember: ({ uuid, userIds }: RemoveItemMembersType) => void;
  scope: 'projects' | 'tasks';
}

function ItemIndexSidebarTeamMembersList({
  isMemberRemoving,
  isUserAssigning,
  item,
  members,
  onAssignUser,
  onRemoveMember,
  scope
}: ItemIndexSidebarTeamMemberProps) {
  return (
    <ul className="-mb-2">
      {!isEmpty(item.project) &&
      item.user?.nanoId !== item.project?.user?.nanoId ? (
        <ItemIndexSidebarTeamTaskCreator scope={scope} item={item} />
      ) : null}
      {isEmpty(members)
        ? null
        : members.map((member) => (
            <ItemIndexSidebarTeamMember
              isMemberRemoving={isMemberRemoving}
              isUserAssigning={isUserAssigning}
              item={item}
              key={member.nanoId}
              member={member}
              onAssignUser={onAssignUser}
              onRemoveMember={onRemoveMember}
              scope={scope}
            />
          ))}
    </ul>
  );
}

export default ItemIndexSidebarTeamMembersList;
