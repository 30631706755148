import * as yup from 'yup';
import toNumber from 'lodash/toNumber';
import { formsErrors } from '../../../../../../../locales/keys';

import { BillFields } from '../../../../../billsTypes';

export const createBillFormSchema = yup.object({
  [BillFields.AMOUNT]: yup
    .number()
    .transform((_, value) => (value === '' ? undefined : toNumber(value)))
    .required(formsErrors.required)
    .when([BillFields.ACCOUNT], {
      is: (value: string) => value === 'payoneer',
      then: yup
        .number()
        .transform((_, value) => (value === '' ? undefined : toNumber(value)))
        .min(20, formsErrors.amount.minAmountPayoneerTwenty)
        .required(formsErrors.required)
    }),
  [BillFields.CURRENCY]: yup.string().required(formsErrors.required),
  [BillFields.ACCOUNT]: yup.string().required(formsErrors.required),
  [BillFields.ALLOCABLE_USER]: yup.string().required(formsErrors.required)
});
