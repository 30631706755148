import React, { ReactNode, useState, useCallback, useRef } from 'react';
import cl from 'classnames';

import { useExpandableDescription } from './hooks/useExpandableDescription';

import { Translate } from '../../../../../../../helpers/Translate';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { MarkdownHelper } from '../../../../../../../helpers/MarkdownHelper';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { words } from '../../../../../../../locales/keys';

interface ItemShowSidebarExpandableDescriptionProps {
  children: ReactNode;
  description: string;
  withoutTitle?: boolean;
}

function ItemShowSidebarExpandableDescription({
  children,
  description,
  withoutTitle
}: ItemShowSidebarExpandableDescriptionProps) {
  const [minimize, setMinimize] = useState<boolean>(true);
  const referenceContainerMarkdownElement = useRef<HTMLDivElement | null>();

  const { showExpandingButton } = useExpandableDescription({
    ref: referenceContainerMarkdownElement
  });

  const toggleMinimize = useCallback<() => void>(
    () => setMinimize((prevState) => !prevState),
    [setMinimize]
  );

  return (
    <div className="p-4">
      {withoutTitle ? null : (
        <div className="flex justify-between mb-3">
          <div>
            <h6 className="font-light uppercase">
              <Translate id={words.description} />
            </h6>
          </div>
        </div>
      )}
      <div
        className={cl(
          minimize
            ? 'line-clamp-6 break-wrap'
            : 'break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug'
        )}
        ref={referenceContainerMarkdownElement}
      >
        <MarkdownHelper source={description} />
      </div>
      <div className="flex justify-between mt-2">
        <div>{children}</div>
        <div>
          {minimize && showExpandingButton ? (
            <PureIconButtonHelper
              className="p-0 space-x-1 space-x-reverse flex-row-reverse inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline underline"
              i18nText={words.showAll}
              i18nTextClassName="hover:underline underline"
              onClick={toggleMinimize}
              icon={IconsEnum.CHEVRON_DOWN_SOLID}
              iconClassName="w-4 h-4"
            />
          ) : null}
          {!minimize ? (
            <PureIconButtonHelper
              className="p-0 space-x-1 space-x-reverse flex-row-reverse inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline underlines"
              i18nText={words.showLess}
              i18nTextClassName="hover:underline underline"
              onClick={toggleMinimize}
              icon={IconsEnum.CHEVRON_UP}
              iconClassName="w-4 h-4"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ItemShowSidebarExpandableDescription;
