import { Fragment, ReactNode } from 'react';
import range from 'lodash/range';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

interface LoadingSidebarSkeletonProps {
  children?: ReactNode;
  loaded?: boolean;
}

const itemDescription = (
  <div className="flex gap-0.5 flex-col">
    <Skeleton
      className="mx-0.5 flex justify-center mb-2"
      containerClassName="flex justify-start items-center w-full"
    />
    {range(3).map((key) => (
      <Skeleton
        key={key}
        className="mx-0.5 flex justify-center"
        containerClassName="flex justify-start items-center w-full"
      />
    ))}
    <Skeleton
      className="mx-0.5 flex justify-center"
      containerClassName="flex justify-start items-center flex-shrink"
      width={100}
    />
  </div>
);

const tagsWidths = [70, 80, 80, 70, 60, 50];

const itemTags = (
  <div className="flex gap-0.5 flex-wrap">
    {tagsWidths.map((width, i) => (
      <Skeleton
        key={i}
        className="mx-0.5 flex justify-center mb-2"
        containerClassName="flex justify-start items-center flex-shrink"
        width={width}
      />
    ))}
  </div>
);

const itemGrid = (
  <div className="grid gap-1 grid-cols-3 max-w-xl">
    {range(6).map((key) => (
      <Skeleton
        key={key}
        className="mx-0.5 flex justify-center"
        containerClassName="aspect-w-1 aspect-h-1 h-auto w-full"
      />
    ))}
  </div>
);

const itemClient = (
  <div className="flex align-items gap-0.5">
    <Skeleton
      className="mx-0.5 flex justify-center"
      containerClassName="flex justify-start items-center flex-shrink"
      width={36}
      height={36}
      circle={true}
    />
    <Skeleton
      className="mx-0.5 flex justify-center"
      containerClassName="flex justify-start items-center w-full"
    />
  </div>
);

const itemTeam = (
  <div className="flex gap-0.5 flex-wrap mb-2">
    <Skeleton
      className="mx-0.5 flex justify-center mb-4"
      containerClassName="flex justify-start items-center flex-shrink"
    />
    {range(6).map((key) => (
      <Skeleton
        key={key}
        className="mx-0.5 flex justify-center"
        containerClassName="flex justify-start items-center flex-shrink"
        width={36}
        height={36}
        circle={true}
      />
    ))}
  </div>
);

function LoadingSidebarSkeleton({
  children,
  loaded
}: LoadingSidebarSkeletonProps) {
  if (loaded) return <Fragment>{children}</Fragment>;

  return (
    <div className="py-4">
      <div className="p-4">{itemDescription}</div>
      <div className="p-4">{itemTags}</div>
      <div className="p-4">{itemGrid}</div>
      <div className="p-4">{itemClient}</div>
      <div className="p-4">{itemTeam}</div>
    </div>
  );
}

export default LoadingSidebarSkeleton;
