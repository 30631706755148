import {
  ProjectCommission,
  ProjectGqlError,
  ProjectUUID,
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateProjectCommissionOptions {
  query: UpdateProjectGqlQuery;
}

export interface UpdateProjectCommissionResponse<
  UpdateProjectCommissionRecordType
> {
  updateProjectCommission: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectUUID;
    record: UpdateProjectCommissionRecordType;
    errors: UpdateProjectCommissionError;
  };
}

export interface UpdateProjectCommissionInput {
  uuid: ProjectUUID;
  commission: ProjectCommission;
}

export interface UpdateProjectCommissionError {
  commission: ProjectGqlError;
  fullMessages: ProjectGqlError;
}

const action = 'updateProjectCommission';

const scope = 'project';

const pluralScope = 'projects';

function useUpdateProjectCommission<UpdateProjectCommissionRecordType>({
  query
}: UpdateProjectCommissionOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateProjectCommissionInput,
    UpdateProjectCommissionResponse<UpdateProjectCommissionRecordType>,
    UpdateProjectCommissionError,
    UpdateProjectCommissionRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateProjectCommissionData: updateQueryData,
    updateProjectCommissionError: updateQueryError,
    updateProjectCommissionLoading: updateQueryLoading,
    updateProjectCommissionErrorMessage: updateQueryErrorMessage,
    updateProjectCommission: updateQuery,
    updateProjectCommissionReset: updateQueryReset
  };
}

export default useUpdateProjectCommission;
