import { gql } from 'graphql-request';

import {
  ProjectEstimateCost,
  ProjectNanoID,
  ProjectUUID
} from '../projectsTypes';

export interface UpdateProjectEstimateCostQueryResponse {
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  estimateCost: ProjectEstimateCost;
}

export const UPDATE_PROJECT_ESTIMATE_COST_QUERY = gql`
  mutation UpdateProjectEstimateCost($uuid: ID!, $estimateCost: Float!) {
    updateProjectEstimateCost(
      input: { uuid: $uuid, estimateCost: $estimateCost }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        estimateCost
      }
      errors {
        estimateCost
        fullMessages
      }
    }
  }
`;
