import { useCallback } from 'react';
import toNumber from 'lodash/toNumber';

import {
  ProjectCommission,
  ProjectFields,
  ProjectUUID
} from '../../../../../projectsTypes';

import {
  UPDATE_PROJECT_COMMISSION_QUERY,
  UpdateProjectCommissionQueryResponse
} from '../../../../../queries/updateProjectCommission.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useUpdateProjectCommission } from '../../../../../hooks/useUpdateProjectCommission';

interface UpdateProjectCommissionFormData {
  commission: string;
}

interface UpdateProjectCommissionFormOptions {
  commission: ProjectCommission;
  uuid: ProjectUUID;
}

function useUpdateProjectCommissionForm({
  commission,
  uuid
}: UpdateProjectCommissionFormOptions) {
  const { handleSubmitReactHookForm, register, setValue } =
    useReactHookForm<UpdateProjectCommissionFormData>({
      defaultValues: {
        commission: commission.toString()
      },
      isModalForm: true
    });

  const handleInitUpdateProjectCommissionForm = useCallback(
    () =>
      setValue(ProjectFields.COMMISSION as 'commission', commission.toString()),
    [commission, setValue]
  );

  const {
    updateProjectCommission,
    updateProjectCommissionLoading,
    updateProjectCommissionErrorMessage,
    updateProjectCommissionReset
  } = useUpdateProjectCommission<UpdateProjectCommissionQueryResponse>({
    query: UPDATE_PROJECT_COMMISSION_QUERY
  });

  return {
    updateProjectCommission,
    updateProjectCommissionLoading,
    updateProjectCommissionErrorMessage,
    updateProjectCommissionReset,
    handleInitUpdateProjectCommissionForm,
    handleUpdateProjectCommission: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateProjectCommissionFormData) =>
        updateProjectCommission({
          uuid,
          commission: toNumber(data.commission)
        })
    }),
    registerCommission: register(ProjectFields.COMMISSION)
  };
}

export default useUpdateProjectCommissionForm;
