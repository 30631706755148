import React from 'react';

import {
  I18nText,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';

export interface UpdateProjectCreditCommissionFormProps {
  form: string;
  i18nPlaceholder?: I18nText;
  isLoading?: IsLoading;
  registerCreditCommission: RegisterFormFieldType<HTMLInputElement>;
}

function UpdateProjectCreditCommissionForm({
  form,
  i18nPlaceholder,
  isLoading,
  registerCreditCommission
}: UpdateProjectCreditCommissionFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div>
          <div>
            <InputField
              disabled={isLoading}
              i18nPlaceholder={i18nPlaceholder}
              inputClassName="basic-input number-appearance-none"
              labelClassName="font-light uppercase"
              name={registerCreditCommission.name}
              onChange={registerCreditCommission.onChange}
              ref={registerCreditCommission.ref}
              type="number"
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default UpdateProjectCreditCommissionForm;
