import {
  ItemSidebarDatesItem,
  ItemSidebarDatesScopeEnum
} from './ItemSidebarDates.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { UpdateTaskImplementationDateModalButton } from '../../../../tasks/components/modalButtons/UpdateTaskImplementationDateModalButton';
import { UpdateProjectImplementationDateModalButton } from '../../../../projects/components/modalButtons/UpdateProjectImplementationDateModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { Translate } from '../../../../../helpers/Translate';

import { formsFields } from '../../../../../locales/keys';

import { ProjectsPermissions } from '../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../tasks/tasksConstants';

interface ItemSidebarDatesProps {
  item: ItemSidebarDatesItem;
  scope: ItemSidebarDatesScopeEnum;
}

function ItemSidebarDates({ item, scope }: ItemSidebarDatesProps) {
  const currentUser = useCurrentUser();

  return (
    <div className="p-4">
      <div className="space-y-1.5">
        {item.setDate ? (
          <div className="flex space-x-2 justify-between">
            <div>
              <Translate id={formsFields.createdAt} />:
            </div>
            <div>
              <DateTimeHelper date={item.setDate} />
            </div>
          </div>
        ) : null}
        {item.startDate ? (
          <div className="flex space-x-2 justify-between">
            <div>
              <Translate id={formsFields.started} />:
            </div>
            <div>
              <DateTimeHelper date={item.startDate} />
            </div>
          </div>
        ) : null}
        {item.implementationDate ? (
          <div className="flex space-x-2 justify-between">
            <div>
              <Translate id={formsFields.implementationDate} />:
            </div>
            {scope === 'projects' ? (
              <CheckPermissions
                action={ProjectsPermissions.READ_PROJECT_IMPLEMENTATION_DATE}
              >
                <UpdateProjectImplementationDateModalButton
                  disabled={
                    !currentUser.hasPermissions(
                      ProjectsPermissions.CHANGE_PROJECT_IMPLEMENTATION_DATE
                    )
                  }
                  implementationDate={item.implementationDate}
                  uuid={item.uuid}
                />
              </CheckPermissions>
            ) : null}
            {scope === 'tasks' ? (
              <CheckPermissions
                action={TasksPermissions.READ_TASK_IMPLEMENTATION_DATE}
              >
                <UpdateTaskImplementationDateModalButton
                  disabled={
                    !currentUser.hasPermissions(
                      TasksPermissions.CHANGE_TASK_IMPLEMENTATION_DATE
                    )
                  }
                  implementationDate={item.implementationDate}
                  uuid={item.uuid}
                />
              </CheckPermissions>
            ) : null}
          </div>
        ) : null}
        {item.implementedAt ? (
          <div className="flex space-x-2 justify-between">
            <div>
              <Translate id={formsFields.implementedAt} />:
            </div>
            <div>
              <DateTimeHelper date={item.implementedAt} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ItemSidebarDates;
