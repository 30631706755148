import React from 'react';

import { I18nText } from '../../../../../types';

import { ProjectCommission, ProjectUUID } from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateProjectCommissionForm } from '../../forms/UpdateProjectCommissionForm/hooks/useUpdateProjectCommissionForm';

import { UpdateProjectCommissionForm } from '../../forms/UpdateProjectCommissionForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { PercentHelper } from '../../../../../helpers/PercentHelper';

import { formsFields, words } from '../../../../../locales/keys';
import { ProjectsPermissions } from '../../../projectsConstants';

interface UpdateProjectCommissionModalButtonProps {
  commission: ProjectCommission;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: ProjectUUID;
}

const UPDATE_CREDIT_COMMISSION_FORM = 'update-project-credit-commission-form';

function UpdateProjectCommissionModalButton({
  commission,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateProjectCommissionModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    handleInitUpdateProjectCommissionForm,
    handleUpdateProjectCommission,
    registerCommission,
    updateProjectCommissionErrorMessage,
    updateProjectCommissionLoading,
    updateProjectCommissionReset
  } = useUpdateProjectCommissionForm({
    commission,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<PercentHelper percent={commission} />}
      className="text-lg font-semibold underline"
      disabled={
        !currentUser.hasPermissions(
          ProjectsPermissions.CHANGE_PROJECT_COMMISSION
        )
      }
      form={UPDATE_CREDIT_COMMISSION_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || formsFields.commission}
      isLoading={updateProjectCommissionLoading}
      onOpen={handleInitUpdateProjectCommissionForm}
      onClose={updateProjectCommissionReset}
      onSubmit={handleUpdateProjectCommission}
    >
      <UpdateProjectCommissionForm
        form={UPDATE_CREDIT_COMMISSION_FORM}
        i18nPlaceholder={i18nPlaceholder || formsFields.commission}
        isLoading={updateProjectCommissionLoading}
        registerCommission={registerCommission}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectCommissionErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectCommissionModalButton;
