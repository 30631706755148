import {
  ErrorMessage,
  I18nText,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';

import { TasksPermissions } from '../../../tasksConstants';

interface UpdateTaskImplementationDateFormProps {
  form: string;
  i18nPlaceholder?: I18nText;
  implementationDateValidationError?: ErrorMessage;
  isLoading?: IsLoading;
  registerTaskImplementationDate: RegisterFormFieldType<HTMLInputElement>;
}

function UpdateTaskImplementationDateForm({
  form,
  i18nPlaceholder,
  implementationDateValidationError,
  isLoading,
  registerTaskImplementationDate
}: UpdateTaskImplementationDateFormProps) {
  const currentUser = useCurrentUser();

  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div>
          <div>
            <InputField
              disabled={isLoading}
              error={implementationDateValidationError}
              i18nPlaceholder={i18nPlaceholder}
              labelClassName="font-light uppercase"
              name={registerTaskImplementationDate.name}
              onChange={registerTaskImplementationDate.onChange}
              ref={registerTaskImplementationDate.ref}
              showErrorIcon={false}
              type={
                currentUser.hasPermissions(
                  TasksPermissions.CHANGE_TASK_IMPLEMENTATION_TIME
                )
                  ? 'datetime-local'
                  : 'date'
              }
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default UpdateTaskImplementationDateForm;
