import React, { useMemo } from 'react';
import includes from 'lodash/includes';

import { ErrorMessage, RegisterFormFieldType } from '../../../../../types';

import { BillFields, CreateBillIsLoading } from '../../../billsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth/useAuth';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import {
  SelectField,
  SelectFieldRequiredProps
} from '../../../../../helpers/FormFields/SelectField';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';

import { billsKeys, formsFields, words } from '../../../../../locales/keys';

import { BillsPermissions } from '../../../billsConstants';

import { CreateBillFormData } from './CreateBillForm.types';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

const currencies = [
  { value: 'USD', i18nLabel: words.usd },
  { value: 'RUB', i18nLabel: words.rub },
  { value: 'UAH', i18nLabel: words.uah }
];

const projectBillTypes = [
  {
    value: 'payoneer',
    i18nLabel: billsKeys.types.payoneer,
    permission: BillsPermissions.READ_PAYONEER_BILL_TYPE
  },
  {
    value: 'payoneer_checkout',
    i18nLabel: billsKeys.types.payoneerCheckout,
    permission: BillsPermissions.READ_PAYONEER_CHECKOUT_BILL_TYPE
  },
  {
    value: '2checkout',
    i18nLabel: billsKeys.types.twoCheckout,
    permission: BillsPermissions.READ_2CHECKOUT_BILL_TYPE
  },
  {
    value: 'fondy',
    i18nLabel: billsKeys.types.fondy,
    permission: BillsPermissions.READ_FONDY_BILL_TYPE
  },
  {
    value: 'stripe',
    i18nLabel: billsKeys.types.stripe,
    permission: BillsPermissions.READ_STRIPE_BILL_TYPE
  },
  {
    value: 'easypay',
    i18nLabel: billsKeys.types.easypay,
    permission: BillsPermissions.READ_EASYPAY_BILL_TYPE
  },
  {
    value: 'invoice',
    i18nLabel: billsKeys.types.invoice,
    permission: BillsPermissions.READ_INVOICE_BILL_TYPE
  },
  {
    value: 'system',
    i18nLabel: billsKeys.types.system,
    permission: BillsPermissions.READ_SYSTEM_BILL_TYPE
  },
  {
    value: 'debit_account',
    i18nLabel: billsKeys.types.debitAccount,
    permission: BillsPermissions.READ_DEBIT_ACCOUNT_BILL_TYPE
  },
  {
    value: 'cancel_debit_account',
    i18nLabel: billsKeys.types.cancelDebitAccount,
    permission: BillsPermissions.READ_CANCEL_DEBIT_ACCOUNT_BILL_TYPE
  },
  {
    value: 'bonus',
    i18nLabel: billsKeys.types.bonus,
    permission: BillsPermissions.READ_BONUS_BILL_TYPE
  },
  {
    value: 'cancel_bonus',
    i18nLabel: billsKeys.types.cancelBonus,
    permission: BillsPermissions.READ_CANCEL_BONUS_BILL_TYPE
  }
];

const taskBillTypes = [
  {
    value: 'task',
    i18nLabel: billsKeys.types.task,
    permission: BillsPermissions.READ_TASK_BILL_TYPE
  },
  {
    value: 'cancel_task',
    i18nLabel: billsKeys.types.cancelTask,
    permission: BillsPermissions.READ_CANCEL_TASK_BILL_TYPE
  }
];

// const accounts = [
//   { value: 'amount_bill', i18nLabel: billsKeys.types.amountBill },
//   { value: 'bank_account_bill', i18nLabel: billsKeys.types.bankAccountBill },
//   { value: 'bonus_bill', i18nLabel: billsKeys.types.bonusBill },
//   {
//     value: 'client_account_bill',
//     i18nLabel: billsKeys.types.clientAccountBill
//   },
//   { value: 'debit_account_bill', i18nLabel: billsKeys.types.debitAccountBill },
//   { value: 'easypay_bill', i18nLabel: billsKeys.types.easypayBill },
//   { value: 'eway_bill', i18nLabel: billsKeys.types.ewayBill },
//   { value: 'fondy_bill', i18nLabel: billsKeys.types.fondyBill },
//   { value: 'interkassa_bill', i18nLabel: billsKeys.types.interkassaBill },
//   { value: 'invoice_bill', i18nLabel: billsKeys.types.invoiceBill },
//   { value: 'liqpay_bill', i18nLabel: billsKeys.types.liqpayBill },
//   { value: 'payoneer_bill', i18nLabel: billsKeys.types.payoneerBill },
//   {
//     value: 'raiffeisen_bank_bill',
//     i18nLabel: billsKeys.types.raiffeisenBankBill
//   },
//   { value: 'rietumu_bill', i18nLabel: billsKeys.types.rietumuBill },
//   { value: 'stripe_bill', i18nLabel: billsKeys.types.stripeBill },
//   {
//     value: 'system_account_bill',
//     i18nLabel: billsKeys.types.systemAccountBill
//   },
//   { value: 'task_bill', i18nLabel: billsKeys.types.taskBill },
//   { value: 'two_checkout_bill', i18nLabel: billsKeys.types.twoCheckoutBill },
//   { value: 'cancel_amount_bill', i18nLabel: billsKeys.types.cancelAmountBill },
//   { value: 'cancel_bonus_bill', i18nLabel: billsKeys.types.cancelBonusBill },
//   {
//     value: 'cancel_debit_account_bill',
//     i18nLabel: billsKeys.types.cancelDebitAccountBill
//   },
//   {
//     value: 'cancel_payment_bill',
//     i18nLabel: billsKeys.types.cancelPaymentBill
//   },
//   { value: 'cancel_task_bill', i18nLabel: billsKeys.types.cancelTaskBill }
// ];

const billTypesWithAllocableUser = ['2checkout', 'fondy', 'stripe', 'easypay'];
const billTypesWithButtons = ['2checkout', 'fondy', 'stripe', 'easypay'];

const allocableUsers = [
  { value: 'client', i18nLabel: words.client },
  { value: 'system', i18nLabel: words.system }
];

const buttons = [
  { value: 'card', i18nLabel: billsKeys.buttonTypes.card },
  { value: 'account', i18nLabel: billsKeys.buttonTypes.account },
  { value: 'card_account', i18nLabel: billsKeys.buttonTypes.cardAccount }
];

interface CreateBillFormProps {
  form: string;
  isLoading: CreateBillIsLoading;
  type: 'project' | 'task';
  registerAmount: RegisterFormFieldType<HTMLInputElement>;
  registerBody: RegisterFormFieldType<HTMLTextAreaElement>;
  amountValidationError: ErrorMessage;
  bodyValidationError: ErrorMessage;
  watchAccount: string;
}

function CreateBillForm({
  control,
  form,
  isLoading,
  type,
  registerAmount,
  registerBody,
  amountValidationError,
  bodyValidationError,
  watchAccount
}: CreateBillFormProps & SelectFieldRequiredProps<CreateBillFormData>) {
  const currentUser = useCurrentUser();

  const actualProjectBillTypes = useMemo(
    () =>
      projectBillTypes.filter((projectBillType) =>
        currentUser.hasPermissions(projectBillType.permission)
      ),
    [currentUser]
  );

  const actualTaskBillTypes = useMemo(
    () =>
      taskBillTypes.filter((taskBillType) =>
        currentUser.hasPermissions(taskBillType.permission)
      ),
    [currentUser]
  );

  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                disabled={isLoading}
                error={amountValidationError}
                i18nLabel={formsFields.amount}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={registerAmount.name}
                onChange={registerAmount.onChange}
                ref={registerAmount.ref}
                type="text"
              />
            </div>
            <div className="w-full sm:w-auto sm:flex-1">
              <SelectField
                control={control}
                disabled={isLoading}
                i18nLabel={formsFields.currency}
                name={BillFields.CURRENCY}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                options={currencies}
                i18nPlaceholder={billsKeys.selectCurrency}
              />
            </div>
          </div>
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <SelectField
                control={control}
                disabled={isLoading}
                i18nLabel={formsFields.account}
                name={BillFields.ACCOUNT}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                options={
                  type === 'project'
                    ? actualProjectBillTypes
                    : actualTaskBillTypes
                }
                i18nPlaceholder={billsKeys.selectAccount}
              />
            </div>
            <CheckPermissions
              action={BillsPermissions.READ_INVOICE_FORM_ALLOCABLE_USER_FIELD}
            >
              {includes(billTypesWithAllocableUser, watchAccount) ? (
                <div className="w-full sm:w-auto sm:flex-1">
                  <SelectField
                    control={control}
                    disabled={isLoading}
                    i18nLabel={billsKeys.selectSource}
                    name={BillFields.ALLOCABLE_USER}
                    labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                    options={allocableUsers}
                    i18nPlaceholder={billsKeys.selectSource}
                  />
                </div>
              ) : null}
            </CheckPermissions>
            {includes(billTypesWithButtons, watchAccount) ? (
              <div className="w-full sm:w-auto sm:flex-1">
                <SelectField
                  control={control}
                  disabled={isLoading}
                  i18nLabel={billsKeys.selectButtons}
                  name={BillFields.BUTTONS}
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  options={buttons}
                  i18nPlaceholder={billsKeys.selectButtons}
                />
              </div>
            ) : null}
          </div>
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <TextAreaField
                name={registerBody.name}
                ref={registerBody.ref}
                onChange={registerBody.onChange}
                error={bodyValidationError}
                i18nLabel={formsFields.description}
                i18nPlaceholder={words.descriptionOptional}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                disabled={isLoading}
                rows={5}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default CreateBillForm;
