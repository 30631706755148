import { ClassName, I18nText } from '../../../../../types';

import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { TaskNanoID } from '../../../tasksTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useCreateTaskForm } from '../../forms/CreateTaskForm/hooks/useCreateTaskForm';

import { CreateTaskForm } from '../../forms/CreateTaskForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { tasksKeys } from '../../../../../locales/keys';

const CREATE_TASK_FORM = 'create-task-form';

interface CreateTaskModalButtonProps {
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  projectNanoId: ProjectNanoID;
  afterCreateTask?: (taskNanoId: TaskNanoID) => void;
  tooltipSingleton?: boolean;
}

function CreateTaskModalButton({
  className,
  icon,
  iconClassName,
  i18nText,
  projectNanoId,
  afterCreateTask,
  tooltipSingleton
}: CreateTaskModalButtonProps) {
  const {
    validationErrors,
    registerFields,
    handleCreateTask,
    resetCreateTaskForm,
    createTaskReset,
    createTaskLoading,
    createTaskErrorMessage,
    control
  } = useCreateTaskForm({ projectNanoId, afterCreateTask });

  return (
    <FormModalButton
      className={className}
      form={CREATE_TASK_FORM}
      i18nTitle={tasksKeys.create}
      i18nText={i18nText}
      i18nTextClassName="hidden sm:inline"
      i18nSubmitText={tasksKeys.create}
      tooltipSingleton={tooltipSingleton}
      icon={icon}
      iconClassName={iconClassName}
      onOpen={resetCreateTaskForm}
      isLoading={createTaskLoading}
      onClose={createTaskReset}
      onSubmit={handleCreateTask}
    >
      <CreateTaskForm
        form={CREATE_TASK_FORM}
        control={control}
        isLoading={createTaskLoading}
        registerName={registerFields.registerName}
        registerDescription={registerFields.registerDescription}
        registerAmount={registerFields.registerAmount}
        registerImplementationDate={registerFields.registerImplementationDate}
        registerWidth={registerFields.registerWidth}
        registerHeight={registerFields.registerHeight}
        registerDpi={registerFields.registerDpi}
        nameValidationError={validationErrors.nameValidationError}
        implementationDateValidationError={
          validationErrors.implementationDateValidationError
        }
        amountValidationError={validationErrors.amountValidationError}
      />

      <div className="px-4">
        <AlertMessage message={createTaskErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default CreateTaskModalButton;
