import isNumber from 'lodash/isNumber';

import { ItemSidebarPaymentsItem } from './ItemSidebarPayments.types';
import { TaskStatuses } from '../../../../tasks/tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { UpdateProjectEstimateCostModalButton } from '../../../../projects/components/modalButtons/UpdateProjectEstimateCostModalButton';
import { UpdateProjectCommissionModalButton } from '../../../../projects/components/modalButtons/UpdateProjectCommissionModalButton';
import { UpdateProjectCreditCommissionModalButton } from '../../../../projects/components/modalButtons/UpdateProjectCreditCommissionModalButton';
import { UpdateProjectHostingCommissionModalButton } from '../../../../projects/components/modalButtons/UpdateProjectHostingCommissionModalButton';
import { UpdateTaskAmountModalButton } from '../../../../tasks/components/modalButtons/UpdateTaskAmountModalButton';
import { UpdateTaskPrepaymentModalButton } from '../../../../tasks/components/modalButtons/UpdateTaskPrepaymentModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { Translate } from '../../../../../helpers/Translate';

import { ProjectsPermissions } from '../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../tasks/tasksConstants';
import { formsFields, words } from '../../../../../locales/keys';

interface ItemSidebarPaymentsProps {
  item: ItemSidebarPaymentsItem;
  scope: 'projects' | 'tasks';
}

function ItemSidebarPayments({ item, scope }: ItemSidebarPaymentsProps) {
  const currentUser = useCurrentUser();

  return (
    <div className="p-4">
      <h6 className="mb-3 font-light uppercase">
        <Translate id={words.payments} />
      </h6>

      <div className="flex flex-wrap text-gray-700 dark:text-gray-300 -mb-3">
        {isNumber(item.estimateCost) ? (
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_ESTIMATE_COST}
          >
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={words.budget} />
              </span>
              <span className="text-lg font-semibold">
                <UpdateProjectEstimateCostModalButton
                  uuid={item.uuid}
                  estimateCost={item.estimateCost}
                />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {isNumber(item.amount) ? (
          <CheckPermissions action={TasksPermissions.READ_TASK_AMOUNT}>
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={formsFields.estimateCost} />
              </span>
              <span className="text-lg font-semibold">
                <UpdateTaskAmountModalButton
                  uuid={item.uuid}
                  amount={item.amount}
                  disabled={item.status === TaskStatuses.DONE}
                />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {isNumber(item.tasksAmountsSum) ? (
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_TASKS_AMOUNTS_SUM}
          >
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={words.expenses} />
              </span>
              <span className="text-lg font-semibold">
                <MoneyHelper value={item.tasksAmountsSum} />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {isNumber(item.prepayment) ? (
          <CheckPermissions action={TasksPermissions.READ_TASK_PREPAYMENT}>
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={words.expenses} />
              </span>
              <span className="text-lg font-semibold">
                <UpdateTaskPrepaymentModalButton
                  disabled={item.status === TaskStatuses.DONE}
                  uuid={item.uuid}
                  prepayment={item.prepayment}
                />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {(scope === 'projects' &&
          currentUser.hasPermissions(ProjectsPermissions.READ_PROJECT_DEBT)) ||
        (scope === 'tasks' &&
          currentUser.hasPermissions(TasksPermissions.READ_TASK_DEBT)) ? (
          <div className="flex flex-col w-1/2 mb-3">
            <span className="text-xs font-medium">
              <Translate id={words.debt} />
            </span>
            <span className="text-lg font-semibold">
              <MoneyHelper value={item?.debt} />
            </span>
          </div>
        ) : null}

        {scope === 'projects' &&
        currentUser.hasPermissions(
          ProjectsPermissions.READ_PROJECT_CLIENT_DEBT
        ) ? (
          <div className="flex flex-col w-1/2 mb-3">
            <span className="text-xs font-medium">
              <Translate id={words.clientDebt} />
            </span>
            <span className="text-lg font-semibold">
              <MoneyHelper value={item?.clientDebt} />
            </span>
          </div>
        ) : null}

        {isNumber(item.projectAccount) ? (
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_PROJECT_ACCOUNT}
          >
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={words.account} />
              </span>
              <span className="text-lg font-semibold">
                <MoneyHelper value={item.projectAccount} />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {isNumber(item.taskAccount) ? (
          <CheckPermissions action={TasksPermissions.READ_TASK_TASK_ACCOUNT}>
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={words.account} />
              </span>
              <span className="text-lg font-semibold">
                <MoneyHelper value={item.taskAccount} />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {isNumber(item.commission) ? (
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_COMMISSION}
          >
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={formsFields.commission} />
              </span>
              <span className="text-lg font-semibold">
                <UpdateProjectCommissionModalButton
                  uuid={item.uuid}
                  commission={item.commission}
                />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {isNumber(item.creditCommission) ? (
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_CREDIT_COMMISSION}
          >
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={formsFields.creditCommission} />
              </span>
              <span className="text-lg font-semibold">
                <UpdateProjectCreditCommissionModalButton
                  uuid={item.uuid}
                  creditCommission={item.creditCommission}
                />
              </span>
            </div>
          </CheckPermissions>
        ) : null}

        {isNumber(item.hostingCommission) ? (
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_HOSTING_COMMISSION}
          >
            <div className="flex flex-col w-1/2 mb-3">
              <span className="text-xs font-medium">
                <Translate id={formsFields.hostingCommission} />
              </span>
              <span className="text-lg font-semibold">
                <UpdateProjectHostingCommissionModalButton
                  uuid={item.uuid}
                  hostingCommission={item.hostingCommission}
                />
              </span>
            </div>
          </CheckPermissions>
        ) : null}
      </div>
    </div>
  );
}

export default ItemSidebarPayments;
