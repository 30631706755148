import { gql } from 'graphql-request';
import {
  TaskID,
  TaskNanoID,
  TaskTagsLocalizedName,
  TaskTagsUUID,
  TaskUUID
} from '../tasksTypes';

export interface UpdateTaskTagIdsQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  tags: {
    uuid: TaskTagsUUID;
    localizedName: TaskTagsLocalizedName;
  };
}

export const UPDATE_TASK_TAG_IDS_QUERY = gql`
  mutation UpdateTaskTagIds($uuid: ID!, $tagIds: [ID!]!) {
    updateTaskTagIds(input: { uuid: $uuid, tagIds: $tagIds }) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        tags {
          uuid
          localizedName
        }
      }
    }
  }
`;
