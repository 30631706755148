import isEmpty from 'lodash/isEmpty';

import { ProjectUUID } from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateProjectTagIdsForm } from '../../forms/UpdateProjectTagIdsForm/hooks/useUpdateProjectTagIdsForm';

import { UpdateProjectTagIdsForm } from '../../forms/UpdateProjectTagIdsForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { Translate } from '../../../../../helpers/Translate';

import { projectsKeys, words } from '../../../../../locales/keys';
import { ProjectsPermissions } from '../../../projectsConstants';
import { ItemIndexSidebarInfoItemTags } from '../../../../common/components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarInfo';

interface UpdateProjectTagIdsModalButtonProps {
  className?: string;
  selectedTags?: ItemIndexSidebarInfoItemTags;
  uuid: ProjectUUID;
}

const UPDATE_PROJECT_TAG_IDS_FORM = 'update-project-tag-ids-form';

function UpdateProjectTagIdsModalButton({
  className,
  selectedTags,
  uuid
}: UpdateProjectTagIdsModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    control,
    handleInitUpdateProjectTagIdForm,
    handleUpdateProjectTagId,
    updateProjectTagIdsErrorMessage,
    updateProjectTagIdsLoading,
    updateProjectTagIdsReset
  } = useUpdateProjectTagIdsForm({
    uuid,
    selectedTags
  });

  return (
    <FormModalButton
      buttonChildren={
        isEmpty(selectedTags) ? (
          <span className="underline px-0.5">
            <Translate id={projectsKeys.addTags} />
          </span>
        ) : (
          selectedTags?.map((tag) => (
            <span
              key={tag.uuid}
              className="inline-flex items-center px-2 py-0.5 m-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:bg-opacity-50"
            >
              {tag.localizedName}
            </span>
          ))
        )
      }
      className={className || 'flex flex-wrap -m-0.5 cursor-pointer'}
      disabled={
        !currentUser.hasPermissions(ProjectsPermissions.CHANGE_PROJECT_TAG_IDS)
      }
      form={UPDATE_PROJECT_TAG_IDS_FORM}
      i18nSubmitText={words.save}
      i18nTitle={projectsKeys.tags}
      isLoading={updateProjectTagIdsLoading}
      onClose={updateProjectTagIdsReset}
      onOpen={handleInitUpdateProjectTagIdForm}
      onSubmit={handleUpdateProjectTagId}
    >
      <UpdateProjectTagIdsForm
        control={control}
        form={UPDATE_PROJECT_TAG_IDS_FORM}
        i18nPlaceholder={projectsKeys.addTags}
        isLoading={updateProjectTagIdsLoading}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectTagIdsErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectTagIdsModalButton;
