import { TaskNanoID, TaskStatusOptions, TaskUUID } from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateTaskStatusOptions {
  query: string;
}

export interface UpdateTaskStatusResponse<UpdateTaskStatusRecordType> {
  updateTaskStatus: {
    status: string;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: UpdateTaskStatusRecordType;
    errors: UpdateTaskStatusError;
  };
}

export interface UpdateTaskStatusInput {
  uuid: TaskUUID;
  status: TaskStatusOptions;
}

export interface UpdateTaskStatusError {
  status: string | null;
  fullMessages: string[] | null;
}

const action = 'updateTaskStatus';

const scope = 'task';

const pluralScope = 'tasks';

function useUpdateTaskStatus<UpdateTaskStatusRecordType>({
  query
}: UpdateTaskStatusOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateTaskStatusInput,
    UpdateTaskStatusResponse<UpdateTaskStatusRecordType>,
    UpdateTaskStatusError,
    UpdateTaskStatusRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateTaskStatusData: updateQueryData,
    updateTaskStatusError: updateQueryError,
    updateTaskStatusLoading: updateQueryLoading,
    updateTaskStatusErrorMessage: updateQueryErrorMessage,
    updateTaskStatus: updateQuery,
    updateTaskStatusReset: updateQueryReset
  };
}

export default useUpdateTaskStatus;
