import React, { useCallback } from 'react';

import {
  AssignUserToItemType,
  IsLoading,
  RemoveItemMembersType
} from '../../../../../../../types';

import {
  ItemIndexSidebarTeamItem,
  ItemIndexSidebarTeamItemUser
} from '../ItemIndexSidebarTeam';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { UserRoleAvatar } from '../../../../../helpers/UserRoleAvatar';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { DropdownHelper } from '../../../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';

import { UserPath } from '../../../../../../users/UserPath';

import { words } from '../../../../../../../locales/keys';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ProjectsPermissions } from '../../../../../../projects/projectsConstants';
import { TasksPermissions } from '../../../../../../tasks/tasksConstants';

interface ItemIndexSidebarTeamMemberProps {
  isMemberRemoving: IsLoading;
  isUserAssigning: IsLoading;
  item: ItemIndexSidebarTeamItem;
  member: ItemIndexSidebarTeamItemUser;
  onAssignUser: ({ uuid, userId }: AssignUserToItemType) => void;
  onRemoveMember: ({ uuid, userIds }: RemoveItemMembersType) => void;
  scope: 'projects' | 'tasks';
}

function ItemIndexSidebarTeamMember({
  isMemberRemoving,
  isUserAssigning,
  item,
  member,
  onAssignUser,
  onRemoveMember,
  scope
}: ItemIndexSidebarTeamMemberProps) {
  const currentUser = useCurrentUser();

  const handleAssignUser = useCallback(
    () => onAssignUser?.({ uuid: item.uuid, userId: member.id }),
    [onAssignUser, member.id, item.uuid]
  );

  const handleRemoveMember = useCallback(
    () => onRemoveMember?.({ uuid: item.uuid, userIds: [member.id] }),
    [onRemoveMember, member.id, item.uuid]
  );

  const showDropDown =
    (scope === 'projects' &&
      currentUser.hasPermissions(
        ProjectsPermissions.READ_PROJECT_TEAM_MEMBER_MENU_DROPDOWN
      )) ||
    (scope === 'tasks' &&
      currentUser.hasPermissions(
        TasksPermissions.READ_TASK_TEAM_MEMBER_MENU_DROPDOWN
      ));

  const showTrackedTime =
    (scope === 'projects' &&
      currentUser.hasPermissions(
        ProjectsPermissions.READ_PROJECT_MEMBER_TRACKED_TIME
      )) ||
    (scope === 'tasks' &&
      currentUser.hasPermissions(
        TasksPermissions.READ_TASK_MEMBER_TRACKED_TIME
      ));

  return (
    <li className="group p-2 -mx-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-850">
      <div className="flex items-center space-x-4">
        <UserRoleAvatar user={member} />
        {showTrackedTime ? (
          <div className="text-sm">{member.trackedTime}</div>
        ) : null}

        {showDropDown ? (
          <DropdownHelper
            dropdownPlacement={PopoverPlacement.LEFT_END}
            icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          >
            <NextPureLinkHelper
              href={UserPath.show(member.nanoId)}
              className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full"
              i18nText={words.viewProfile}
            />
            <PureButtonHelper
              className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full"
              disabled={isUserAssigning}
              i18nText={words.assignAsCreator}
              onClick={handleAssignUser}
            />
            <PureButtonHelper
              className="text-left flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full"
              disabled={isMemberRemoving}
              i18nText={`models.${scope}.remove_from`}
              onClick={handleRemoveMember}
            />
          </DropdownHelper>
        ) : null}
      </div>
    </li>
  );
}

export default ItemIndexSidebarTeamMember;
