import React from 'react';

import { I18nText } from '../../../../../types';

import { ProjectCreditCommission, ProjectUUID } from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateProjectCreditCommissionForm } from '../../forms/UpdateProjectCreditCommissionForm/hooks/useUpdateProjectCreditCommissionForm';

import { UpdateProjectCreditCommissionForm } from '../../forms/UpdateProjectCreditCommissionForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { PercentHelper } from '../../../../../helpers/PercentHelper';

import { formsFields, words } from '../../../../../locales/keys';
import { ProjectsPermissions } from '../../../projectsConstants';

interface UpdateProjectCreditCommissionModalButtonProps {
  creditCommission: ProjectCreditCommission;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: ProjectUUID;
}

const UPDATE_CREDIT_COMMISSION_FORM = 'update-project-credit-commission-form';

function UpdateProjectCreditCommissionModalButton({
  creditCommission,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateProjectCreditCommissionModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    handleInitUpdateProjectCreditCommissionForm,
    handleUpdateProjectCreditCommission,
    registerCreditCommission,
    updateProjectCreditCommissionErrorMessage,
    updateProjectCreditCommissionLoading,
    updateProjectCreditCommissionReset
  } = useUpdateProjectCreditCommissionForm({
    creditCommission,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<PercentHelper percent={creditCommission} />}
      className="text-lg font-semibold underline"
      disabled={
        !currentUser.hasPermissions(
          ProjectsPermissions.CHANGE_PROJECT_CREDIT_COMMISSION
        )
      }
      form={UPDATE_CREDIT_COMMISSION_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || formsFields.creditCommission}
      isLoading={updateProjectCreditCommissionLoading}
      onOpen={handleInitUpdateProjectCreditCommissionForm}
      onClose={updateProjectCreditCommissionReset}
      onSubmit={handleUpdateProjectCreditCommission}
    >
      <UpdateProjectCreditCommissionForm
        form={UPDATE_CREDIT_COMMISSION_FORM}
        i18nPlaceholder={i18nPlaceholder || formsFields.creditCommission}
        isLoading={updateProjectCreditCommissionLoading}
        registerCreditCommission={registerCreditCommission}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectCreditCommissionErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectCreditCommissionModalButton;
