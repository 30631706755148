import { useCallback } from 'react';

import {
  TaskImplementationDate,
  TaskFields,
  TaskUUID
} from '../../../../../tasksTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateTaskImplementationDate } from '../../../../../hooks/useUpdateTaskImplementationDate';
import { useUpdateTaskImplementationDateValidationRules } from '../useUpdateTaskImplementationDateValidationRules';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  UpdateTaskImplementationDateQueryResponse,
  UPDATE_TASK_IMPLEMENTATION_DATE_QUERY
} from '../../../../../queries/updateTaskImplementationDate.query';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';
import { getEndOfDay } from '../../../../../../../utils/getEndOfDay';

import { TasksPermissions } from '../../../../../tasksConstants';

interface UpdateTaskImplementationDateFormData {
  implementationDate?: TaskImplementationDate;
}

interface UpdateTaskImplementationDateFormOptions {
  implementationDate: TaskImplementationDate;
  uuid: TaskUUID;
}

function useUpdateTaskImplementationDateForm({
  implementationDate,
  uuid
}: UpdateTaskImplementationDateFormOptions) {
  const currentUser = useCurrentUser();

  const { handleSubmitReactHookForm, isDirty, register, resetForm, errors } =
    useReactHookForm<UpdateTaskImplementationDateFormData>({
      defaultValues: {
        implementationDate: currentUser.hasPermissions(
          TasksPermissions.CHANGE_TASK_IMPLEMENTATION_TIME
        )
          ? getEndOfDay(implementationDate)
          : dateFnsConvert.toDate(implementationDate)
      },
      isModalForm: true
    });

  const { implementationDateRules } =
    useUpdateTaskImplementationDateValidationRules();

  const handleInitUpdateTaskImplementationDateForm = useCallback(() => {
    resetForm({
      implementationDate: currentUser.hasPermissions(
        TasksPermissions.CHANGE_TASK_IMPLEMENTATION_TIME
      )
        ? getEndOfDay(implementationDate)
        : dateFnsConvert.toDate(implementationDate)
    });
  }, [currentUser, implementationDate, resetForm]);

  const {
    updateTaskImplementationDate,
    updateTaskImplementationDateLoading,
    updateTaskImplementationDateErrorMessage,
    updateTaskImplementationDateReset
  } = useUpdateTaskImplementationDate<UpdateTaskImplementationDateQueryResponse>(
    {
      query: UPDATE_TASK_IMPLEMENTATION_DATE_QUERY
    }
  );

  return {
    updateTaskImplementationDate,
    updateTaskImplementationDateLoading,
    updateTaskImplementationDateErrorMessage,
    updateTaskImplementationDateReset,
    handleInitUpdateTaskImplementationDateForm,
    implementationDateValidationError: errors?.implementationDate?.message,
    handleUpdateTaskImplementationDate: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateTaskImplementationDateFormData) =>
        updateTaskImplementationDate({
          uuid,
          implementationDate: dateFnsConvert.toDateTimeWithTimezone(
            currentUser.hasPermissions(
              TasksPermissions.CHANGE_TASK_IMPLEMENTATION_TIME
            )
              ? data.implementationDate
              : getEndOfDay(data.implementationDate)
          )
        })
    }),
    registerTaskImplementationDate: register(
      TaskFields.IMPLEMENTATION_DATE,
      implementationDateRules
    ),
    isDirty
  };
}

export default useUpdateTaskImplementationDateForm;
