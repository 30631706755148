import {
  ProjectHostingCommission,
  ProjectGqlError,
  ProjectUUID,
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus,
  ProjectNanoID
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateProjectHostingCommissionOptions {
  query: UpdateProjectGqlQuery;
}

export interface UpdateProjectHostingCommissionResponse<
  UpdateProjectHostingCommissionRecordType
> {
  updateProjectHostingCommission: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: UpdateProjectHostingCommissionRecordType;
    errors: UpdateProjectHostingCommissionError;
  };
}

export interface UpdateProjectHostingCommissionInput {
  uuid: ProjectUUID;
  hostingCommission: ProjectHostingCommission;
}

export interface UpdateProjectHostingCommissionError {
  hostingCommission: ProjectGqlError;
  fullMessages: ProjectGqlError;
}

const action = 'updateProjectHostingCommission';

const scope = 'project';

const pluralScope = 'projects';

function useUpdateProjectHostingCommission<
  UpdateProjectHostingCommissionRecordType
>({ query }: UpdateProjectHostingCommissionOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateProjectHostingCommissionInput,
    UpdateProjectHostingCommissionResponse<UpdateProjectHostingCommissionRecordType>,
    UpdateProjectHostingCommissionError,
    UpdateProjectHostingCommissionRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateProjectHostingCommissionData: updateQueryData,
    updateProjectHostingCommissionError: updateQueryError,
    updateProjectHostingCommissionLoading: updateQueryLoading,
    updateProjectHostingCommissionErrorMessage: updateQueryErrorMessage,
    updateProjectHostingCommission: updateQuery,
    updateProjectHostingCommissionReset: updateQueryReset
  };
}

export default useUpdateProjectHostingCommission;
