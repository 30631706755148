import { detectOverflow, Options } from '@popperjs/core';
import { useState } from 'react';
import { usePopper } from 'react-popper';

const popperOptions: Options = {
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 6]
      }
    },
    {
      name: 'sameWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      }
    },
    {
      name: 'maxSize',
      enabled: true,
      phase: 'main',
      requiresIfExists: ['offset', 'preventOverflow', 'flip'],
      fn({ state, name, options }) {
        const overflow = detectOverflow(state, options);
        const { x, y } = state.modifiersData.preventOverflow || {
          x: 0,
          y: 0
        };
        const { width, height } = state.rects.popper;
        const [basePlacement] = state.placement.split('-');

        const widthProp = basePlacement === 'left' ? 'left' : 'right';
        const heightProp = basePlacement === 'top' ? 'top' : 'bottom';

        state.modifiersData[name] = {
          width: width - overflow[widthProp] - x,
          height: height - overflow[heightProp] - y
        };
      },
      options: {
        boundary: 'viewport',
        padding: 12
      }
    },
    {
      name: 'applyMaxSize',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['maxSize'],
      fn({ state }) {
        const { height } = state.modifiersData.maxSize;
        state.styles.popper.maxHeight = `${height}px`;
      }
    }
  ]
};

function useSelectPopper() {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(
    null
  );

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    popperOptions
  );

  return {
    setPopperElement,
    setReferenceElement,
    popperStyles: styles,
    popperAttributes: attributes
  };
}

export default useSelectPopper;
