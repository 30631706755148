import {
  TaskGqlError,
  TaskImplementationDate,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { TaskCache } from '../../TaskCache';

interface UpdateTaskImplementationDateOptions {
  query: UpdateTaskGqlQuery;
}

export interface UpdateTaskImplementationDateResponse<UpdateTaskRecordType> {
  updateTaskImplementationDate: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: UpdateTaskRecordType;
    errors: UpdateTaskImplementationDateError;
  };
}

export interface UpdateTaskImplementationDateInput {
  uuid: TaskUUID;
  implementationDate?: TaskImplementationDate;
}

export interface UpdateTaskImplementationDateError {
  implementationDate: TaskGqlError;
  fullMessages: TaskGqlError;
}

const action = 'updateTaskImplementationDate';
const scope = TaskCache.showCacheKey();
const pluralScope = TaskCache.indexCacheKey();

function useUpdateTaskImplementationDate<UpdateTaskRecordType>({
  query
}: UpdateTaskImplementationDateOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateTaskImplementationDateInput,
    UpdateTaskImplementationDateResponse<UpdateTaskRecordType>,
    UpdateTaskImplementationDateError,
    UpdateTaskRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateTaskImplementationDateData: updateQueryData,
    updateTaskImplementationDateError: updateQueryError,
    updateTaskImplementationDateLoading: updateQueryLoading,
    updateTaskImplementationDateErrorMessage: updateQueryErrorMessage,
    updateTaskImplementationDate: updateQuery,
    updateTaskImplementationDateReset: updateQueryReset
  };
}

export default useUpdateTaskImplementationDate;
