import { Control } from 'react-hook-form';

import { IsLoading } from '../../../../../types';
import { UpdateTaskTagIdsFormTagIdsFields } from './UpdateProjectTagIdsForm.types';

import { UpdateProjectTagIdFormData } from './hooks/useUpdateProjectTagIdsForm';

import { Form } from '../../../../../helpers/Form';
import { TagsMultiSelectField } from '../../../../tags/helpers/TagsMultiSelectField';

export interface UpdateProjectTagIdsFormProps {
  control: Control<UpdateProjectTagIdFormData>;
  form: string;
  isLoading: IsLoading;
  i18nPlaceholder?: string;
}

function UpdateProjectTagIdsForm({
  control,
  form,
  isLoading,
  i18nPlaceholder
}: UpdateProjectTagIdsFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2 text-sm" id={form}>
      <div className="p-4">
        <TagsMultiSelectField<UpdateProjectTagIdFormData>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          isLoading={isLoading}
          control={control}
          i18nPlaceholder={i18nPlaceholder}
          name={UpdateTaskTagIdsFormTagIdsFields.TAG_IDS}
          multi
        />
      </div>
    </Form>
  );
}

export default UpdateProjectTagIdsForm;
