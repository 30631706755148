import { useItemDueDateValidationRules } from '../../../../../../common/forms/hooks/useItemDueDateValidationRules';

import { ProjectsPermissions } from '../../../../../projectsConstants';

function useUpdateProjectImplementationDateValidationRules() {
  const implementationDateRules = useItemDueDateValidationRules({
    action: ProjectsPermissions.CHANGE_PROJECT_IMPLEMENTATION_TIME
  });

  return {
    implementationDateRules
  };
}

export default useUpdateProjectImplementationDateValidationRules;
