import { ID } from '../../../../types';
import {
  TaskGqlError,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlStatus
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { TaskCache } from '../../TaskCache';

interface UpdateTaskTagIdsOptions {
  query: string;
}

export interface UpdateTaskTagIdsResponse<UpdateTaskTagIdsRecordType> {
  updateTaskTagIds: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: UpdateTaskTagIdsRecordType;
    errors: UpdateTaskTagIdsError;
  };
}

export interface UpdateTaskTagIdsInput {
  uuid: TaskUUID;
  tagIds: ID[];
}

export interface UpdateTaskTagIdsError {
  fullMessages: TaskGqlError;
}

const action = 'updateTaskTagIds';
const scope = TaskCache.showCacheKey();
const pluralScope = TaskCache.indexCacheKey();

function useUpdateTaskTagIds<UpdateTaskTagIdsRecordType>({
  query
}: UpdateTaskTagIdsOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    UpdateTaskTagIdsInput,
    UpdateTaskTagIdsResponse<UpdateTaskTagIdsRecordType>,
    UpdateTaskTagIdsError,
    UpdateTaskTagIdsRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateTaskTagIds: updateQuery,
    updateTaskTagIdsData: updateQueryData,
    updateTaskTagIdsError: updateQueryError,
    updateTaskTagIdsErrorMessage: updateQueryErrorMessage,
    updateTaskTagIdsLoading: updateQueryLoading,
    updateTaskTagIdsReset: updateQueryReset
  };
}

export default useUpdateTaskTagIds;
