import React from 'react';

import {
  FieldIcons,
  I18nText,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';

interface UpdateProjectEstimateCostFormProps {
  form: string;
  i18nPlaceholder?: I18nText;
  isLoading?: IsLoading;
  registerEstimateCost: RegisterFormFieldType<HTMLInputElement>;
}

function UpdateProjectEstimateCostForm({
  form,
  i18nPlaceholder,
  isLoading,
  registerEstimateCost
}: UpdateProjectEstimateCostFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div>
          <div>
            <InputField
              className="relative"
              disabled={isLoading}
              i18nPlaceholder={i18nPlaceholder}
              inputClassName="basic-input pl-7 number-appearance-none"
              labelClassName="font-light uppercase"
              leftIcon={FieldIcons.DOLLAR}
              name={registerEstimateCost.name}
              onChange={registerEstimateCost.onChange}
              ref={registerEstimateCost.ref}
              type="number"
            />
          </div>
        </div>
      </div>
    </Form>
  );
}

export default UpdateProjectEstimateCostForm;
