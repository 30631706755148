import { useItemDueDateValidationRules } from '../../../../../../common/forms/hooks/useItemDueDateValidationRules';

import { formsErrors } from '../../../../../../../locales/keys';

import { TasksPermissions } from '../../../../../tasksConstants';

const nameRules = { required: formsErrors.taskName.required };

function useCreateTaskValidationRules() {
  const implementationDateRules = useItemDueDateValidationRules({
    action: TasksPermissions.CREATE_TASK_IMPLEMENTATION_TIME,
    cantBeBlank: false
  });

  return {
    implementationDateRules,
    nameRules
  };
}

export default useCreateTaskValidationRules;
