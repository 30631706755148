import React from 'react';

import { I18nText } from '../../../../../types';

import { ProjectHostingCommission, ProjectUUID } from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateProjectHostingCommissionForm } from '../../forms/UpdateProjectHostingCommissionForm/hooks/useUpdateProjectHostingCommissionForm';

import { UpdateProjectHostingCommissionForm } from '../../forms/UpdateProjectHostingCommissionForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { PercentHelper } from '../../../../../helpers/PercentHelper';

import { formsFields, words } from '../../../../../locales/keys';
import { ProjectsPermissions } from '../../../projectsConstants';

interface UpdateProjectHostingCommissionModalButtonProps {
  hostingCommission: ProjectHostingCommission;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: ProjectUUID;
}

const UPDATE_HOSTING_COMMISSION_FORM = 'update-project-hosting-commission-form';

function UpdateProjectHostingCommissionModalButton({
  hostingCommission,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateProjectHostingCommissionModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    handleInitUpdateProjectHostingCommissionForm,
    handleUpdateProjectHostingCommission,
    registerHostingCommission,
    updateProjectHostingCommissionErrorMessage,
    updateProjectHostingCommissionLoading,
    updateProjectHostingCommissionReset
  } = useUpdateProjectHostingCommissionForm({
    hostingCommission,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<PercentHelper percent={hostingCommission} />}
      className="text-lg font-semibold underline"
      disabled={
        !currentUser.hasPermissions(
          ProjectsPermissions.CHANGE_PROJECT_HOSTING_COMMISSION
        )
      }
      form={UPDATE_HOSTING_COMMISSION_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || formsFields.hostingCommission}
      isLoading={updateProjectHostingCommissionLoading}
      onOpen={handleInitUpdateProjectHostingCommissionForm}
      onClose={updateProjectHostingCommissionReset}
      onSubmit={handleUpdateProjectHostingCommission}
    >
      <UpdateProjectHostingCommissionForm
        form={UPDATE_HOSTING_COMMISSION_FORM}
        i18nPlaceholder={i18nPlaceholder || formsFields.hostingCommission}
        isLoading={updateProjectHostingCommissionLoading}
        registerHostingCommission={registerHostingCommission}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectHostingCommissionErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectHostingCommissionModalButton;
