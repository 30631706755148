import compact from 'lodash/compact';

import { ProjectNanoID } from '../../../../../../projects/projectsTypes';

import { TaskFields, TaskNanoID } from '../../../../../tasksTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useCreateTaskWithProjectNanoId } from '../../../../../hooks/useCreateTaskWithProjectNanoId';
import { useCreateTaskValidationRules } from '../useCreateTaskValidationRules';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';
import { getEndOfDay } from '../../../../../../../utils/getEndOfDay';

import { CREATE_TASK_WITH_PROJECT_NANO_ID_QUERY } from '../../../../../queries/createTaskWithProjectNanoId.query';

import { ProjectCache } from '../../../../../../projects/ProjectCache';

import { CreateTaskFormData } from '../../CreateTaskForm.types';
import { TasksPermissions } from '../../../../../tasksConstants';

const defaultTaskValues: CreateTaskFormData = {
  name: '',
  description: '',
  implementationDate: getEndOfDay(new Date()),
  amount: null,
  fileAttachmentIds: []
};

interface CreateTaskFormOptions {
  projectNanoId: ProjectNanoID;
  afterCreateTask?: (taskNanoId: TaskNanoID) => void;
}

function useCreateTaskForm({
  projectNanoId,
  afterCreateTask
}: CreateTaskFormOptions) {
  const currentUser = useCurrentUser();

  const { control, errors, handleSubmitReactHookForm, register, resetForm } =
    useReactHookForm<CreateTaskFormData>({
      defaultValues: {
        ...defaultTaskValues,
        implementationDate: currentUser.hasPermissions(
          TasksPermissions.CREATE_TASK_IMPLEMENTATION_TIME
        )
          ? defaultTaskValues.implementationDate
          : dateFnsConvert.toDate(defaultTaskValues.implementationDate)
      },
      isModalForm: true
    });

  const { implementationDateRules, nameRules } = useCreateTaskValidationRules();

  const cacheKeys = compact([
    projectNanoId && ProjectCache.tasksCacheKey(projectNanoId)
  ]);

  const {
    createTaskReset,
    createTaskLoading,
    createTaskErrorMessage,
    createTask
  } = useCreateTaskWithProjectNanoId({
    query: CREATE_TASK_WITH_PROJECT_NANO_ID_QUERY,
    cacheKeys
  });

  return {
    validationErrors: {
      nameValidationError: errors?.name?.message,
      implementationDateValidationError: errors?.implementationDate?.message,
      amountValidationError: errors?.amount?.message
    },
    control,
    resetCreateTaskForm: resetForm,
    createTaskReset,
    createTaskErrorMessage,
    createTaskLoading,
    handleCreateTask: handleSubmitReactHookForm({
      onSubmit: async (data) =>
        createTask({
          ...data,
          projectNanoId,
          implementationDate: data.implementationDate
            ? dateFnsConvert.toDateTimeWithTimezone(
                currentUser.hasPermissions(
                  TasksPermissions.CREATE_TASK_IMPLEMENTATION_TIME
                )
                  ? data.implementationDate
                  : getEndOfDay(data.implementationDate)
              )
            : undefined,
          amount: data.amount === '' ? undefined : parseFloat(data.amount),
          width: data.width === '' ? undefined : parseInt(data.width, 10),
          height: data.height === '' ? undefined : parseInt(data.height, 10),
          dpi: data.dpi === '' ? undefined : parseFloat(data.dpi),
          fileExtension: data.fileExtension
            ? (data.fileExtension as 'jpg' | 'png' | 'tiff')
            : undefined
        }).then((data) => afterCreateTask?.(data.createTask?.recordNanoId)),
      dirtyFieldsOnly: false
    }),
    registerFields: {
      registerName: register(TaskFields.NAME, nameRules),
      registerDescription: register(TaskFields.DESCRIPTION),
      registerAmount: register(TaskFields.AMOUNT),
      registerImplementationDate: register(
        TaskFields.IMPLEMENTATION_DATE,
        implementationDateRules
      ),
      registerWidth: register(TaskFields.WIDTH),
      registerHeight: register(TaskFields.HEIGHT),
      registerDpi: register(TaskFields.DPI),
      registerFileExtension: register(TaskFields.FILE_EXTENSION)
    }
  };
}

export default useCreateTaskForm;
