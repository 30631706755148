import React from 'react';

import { I18nText } from '../../../../../types';

import { ProjectEstimateCost, ProjectUUID } from '../../../projectsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateProjectEstimateCostForm } from '../../forms/UpdateProjectEstimateCostForm/hooks/useUpdateProjectEstimateCostForm';

import { UpdateProjectEstimateCostForm } from '../../forms/UpdateProjectEstimateCostForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';

import { formsFields, words } from '../../../../../locales/keys';
import { ProjectsPermissions } from '../../../projectsConstants';

interface UpdateProjectEstimateCostModalButtonProps {
  estimateCost: ProjectEstimateCost;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: ProjectUUID;
}

const UPDATE_ESTIMATE_COST_FORM = 'update-project-estimate-cost-form';

function UpdateProjectEstimateCostModalButton({
  estimateCost,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateProjectEstimateCostModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    handleInitUpdateProjectEstimateCostForm,
    handleUpdateProjectEstimateCost,
    registerEstimateCost,
    updateProjectEstimateCostErrorMessage,
    updateProjectEstimateCostLoading,
    updateProjectEstimateCostReset
  } = useUpdateProjectEstimateCostForm({
    estimateCost,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<MoneyHelper value={estimateCost} />}
      className="text-lg font-semibold underline"
      disabled={
        !currentUser.hasPermissions(
          ProjectsPermissions.CHANGE_PROJECT_ESTIMATE_COST
        )
      }
      form={UPDATE_ESTIMATE_COST_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || words.budget}
      isLoading={updateProjectEstimateCostLoading}
      onOpen={handleInitUpdateProjectEstimateCostForm}
      onClose={updateProjectEstimateCostReset}
      onSubmit={handleUpdateProjectEstimateCost}
    >
      <UpdateProjectEstimateCostForm
        form={UPDATE_ESTIMATE_COST_FORM}
        i18nPlaceholder={i18nPlaceholder || words.budget}
        isLoading={updateProjectEstimateCostLoading}
        registerEstimateCost={registerEstimateCost}
      />

      <div className="px-4">
        <AlertMessage message={updateProjectEstimateCostErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateProjectEstimateCostModalButton;
