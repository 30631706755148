import {
  ProjectEstimateCost,
  ProjectGqlError,
  ProjectNanoID,
  ProjectUUID,
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateProjectEstimateCostOptions {
  query: UpdateProjectGqlQuery;
}

export interface UpdateProjectEstimateCostResponse<
  UpdateProjectEstimateCostRecordType
> {
  updateProjectEstimateCost: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: UpdateProjectEstimateCostRecordType;
    errors: UpdateProjectEstimateCostError;
  };
}

export interface UpdateProjectEstimateCostInput {
  uuid: ProjectUUID;
  estimateCost: ProjectEstimateCost;
}

export interface UpdateProjectEstimateCostError {
  estimateCost: ProjectGqlError;
  fullMessages: ProjectGqlError;
}

const action = 'updateProjectEstimateCost';

const scope = 'project';

const pluralScope = 'projects';

function useUpdateProjectEstimateCost<UpdateProjectEstimateCostRecordType>({
  query
}: UpdateProjectEstimateCostOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateProjectEstimateCostInput,
    UpdateProjectEstimateCostResponse<UpdateProjectEstimateCostRecordType>,
    UpdateProjectEstimateCostError,
    UpdateProjectEstimateCostRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateProjectEstimateCostData: updateQueryData,
    updateProjectEstimateCostError: updateQueryError,
    updateProjectEstimateCostLoading: updateQueryLoading,
    updateProjectEstimateCostErrorMessage: updateQueryErrorMessage,
    updateProjectEstimateCost: updateQuery,
    updateProjectEstimateCostReset: updateQueryReset
  };
}

export default useUpdateProjectEstimateCost;
