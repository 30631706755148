import isEmpty from 'lodash/isEmpty';

import {
  TaskTagsID,
  TaskTagsLocalizedName,
  TaskTagsUUID,
  TaskUUID
} from '../../../tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateTaskTagIdsForm } from '../../forms/UpdateTaskTagIdsForm/hooks/useUpdateTaskTagIdsForm';

import { UpdateTaskTagIdsForm } from '../../forms/UpdateTaskTagIdsForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { Translate } from '../../../../../helpers/Translate';

import { tasksKeys, words } from '../../../../../locales/keys';
import { TasksPermissions } from '../../../tasksConstants';

interface UpdateTaskTagIdsModalButtonProps {
  className?: string;
  selectedTags?: {
    id: TaskTagsID;
    uuid: TaskTagsUUID;
    localizedName: TaskTagsLocalizedName;
  }[];
  uuid: TaskUUID;
}

const UPDATE_TASK_TAG_IDS_FORM = 'update-task-tag-ids-form';

function UpdateTaskTagIdsModalButton({
  className,
  selectedTags,
  uuid
}: UpdateTaskTagIdsModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    control,
    handleInitUpdateTaskTagIdForm,
    handleUpdateTaskTagId,
    updateTaskTagIdsErrorMessage,
    updateTaskTagIdsLoading,
    updateTaskTagIdsReset
  } = useUpdateTaskTagIdsForm({
    uuid,
    selectedTags
  });

  return (
    <FormModalButton
      buttonChildren={
        isEmpty(selectedTags) ? (
          <span className="underline px-0.5">
            <Translate id={tasksKeys.addTags} />
          </span>
        ) : (
          selectedTags?.map((tag) => (
            <span
              key={tag.uuid}
              className="inline-flex items-center px-2 py-0.5 m-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:bg-opacity-50"
            >
              {tag.localizedName}
            </span>
          ))
        )
      }
      className={className || 'flex flex-wrap -m-0.5 cursor-pointer'}
      disabled={
        !currentUser.hasPermissions(TasksPermissions.CHANGE_TASK_TAG_IDS)
      }
      form={UPDATE_TASK_TAG_IDS_FORM}
      i18nSubmitText={words.save}
      i18nTitle={tasksKeys.tags}
      isLoading={updateTaskTagIdsLoading}
      onClose={updateTaskTagIdsReset}
      onOpen={handleInitUpdateTaskTagIdForm}
      onSubmit={handleUpdateTaskTagId}
    >
      <UpdateTaskTagIdsForm
        control={control}
        form={UPDATE_TASK_TAG_IDS_FORM}
        i18nPlaceholder={tasksKeys.addTags}
        isLoading={updateTaskTagIdsLoading}
      />

      <div className="px-4">
        <AlertMessage message={updateTaskTagIdsErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateTaskTagIdsModalButton;
