import React from 'react';

import { I18nText, IsDisabled } from '../../../../../types';

import { TaskPrepayment, TaskUUID } from '../../../tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateTaskPrepaymentForm } from '../../forms/UpdateTaskPrepaymentForm/hooks/useUpdateTaskPrepaymentForm';

import { UpdateTaskPrepaymentForm } from '../../forms/UpdateTaskPrepaymentForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';

import { formsFields, words } from '../../../../../locales/keys';
import { TasksPermissions } from '../../../tasksConstants';

interface UpdateTaskPrepaymentModalButtonProps {
  disabled?: IsDisabled;
  prepayment: TaskPrepayment;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: TaskUUID;
}

const UPDATE_PREPAYMENT_FORM = 'update-task-prepayment-form';

function UpdateTaskPrepaymentModalButton({
  disabled,
  prepayment,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateTaskPrepaymentModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    handleInitUpdateTaskPrepaymentForm,
    handleUpdateTaskPrepayment,
    registerPrepayment,
    updateTaskPrepaymentErrorMessage,
    updateTaskPrepaymentLoading,
    updateTaskPrepaymentReset
  } = useUpdateTaskPrepaymentForm({
    prepayment,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<MoneyHelper value={prepayment} />}
      className="text-lg font-semibold underline"
      disabled={
        disabled ||
        !currentUser.hasPermissions(TasksPermissions.CHANGE_TASK_PREPAYMENT)
      }
      form={UPDATE_PREPAYMENT_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || formsFields.prepayment}
      isLoading={updateTaskPrepaymentLoading}
      onOpen={handleInitUpdateTaskPrepaymentForm}
      onClose={updateTaskPrepaymentReset}
      onSubmit={handleUpdateTaskPrepayment}
    >
      <UpdateTaskPrepaymentForm
        form={UPDATE_PREPAYMENT_FORM}
        i18nPlaceholder={i18nPlaceholder || formsFields.prepayment}
        isLoading={updateTaskPrepaymentLoading}
        registerPrepayment={registerPrepayment}
      />

      <div className="px-4">
        <AlertMessage message={updateTaskPrepaymentErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateTaskPrepaymentModalButton;
