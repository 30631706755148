import { useCallback } from 'react';
import toNumber from 'lodash/toNumber';

import {
  TaskPrepayment,
  TaskFields,
  TaskUUID
} from '../../../../../tasksTypes';

import {
  UPDATE_TASK_PREPAYMENT_QUERY,
  UpdateTaskPrepaymentQueryResponse
} from '../../../../../queries/updateTaskPrepayment.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useUpdateTaskPrepayment } from '../../../../../hooks/useUpdateTaskPrepayment';

interface UpdateTaskPrepaymentFormData {
  prepayment?: string;
}

interface UpdateTaskPrepaymentFormOptions {
  prepayment: TaskPrepayment;
  uuid: TaskUUID;
}

function useUpdateTaskPrepaymentForm({
  prepayment,
  uuid
}: UpdateTaskPrepaymentFormOptions) {
  const { handleSubmitReactHookForm, register, setValue } =
    useReactHookForm<UpdateTaskPrepaymentFormData>({
      defaultValues: {
        prepayment: prepayment.toString()
      },
      isModalForm: true
    });

  const handleInitUpdateTaskPrepaymentForm = useCallback(
    () =>
      setValue(TaskFields.PREPAYMENT as 'prepayment', prepayment.toString()),
    [prepayment, setValue]
  );

  const {
    updateTaskPrepayment,
    updateTaskPrepaymentLoading,
    updateTaskPrepaymentErrorMessage,
    updateTaskPrepaymentReset
  } = useUpdateTaskPrepayment<UpdateTaskPrepaymentQueryResponse>({
    query: UPDATE_TASK_PREPAYMENT_QUERY
  });

  return {
    updateTaskPrepayment,
    updateTaskPrepaymentLoading,
    updateTaskPrepaymentErrorMessage,
    updateTaskPrepaymentReset,
    handleInitUpdateTaskPrepaymentForm,
    handleUpdateTaskPrepayment: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateTaskPrepaymentFormData) =>
        updateTaskPrepayment({
          uuid,
          prepayment: toNumber(data.prepayment)
        })
    }),
    registerPrepayment: register(TaskFields.PREPAYMENT)
  };
}

export default useUpdateTaskPrepaymentForm;
