import { useCallback } from 'react';
import toNumber from 'lodash/toNumber';

import {
  ProjectHostingCommission,
  ProjectFields,
  ProjectUUID
} from '../../../../../projectsTypes';

import {
  UPDATE_PROJECT_HOSTING_COMMISSION_QUERY,
  UpdateProjectHostingCommissionQueryResponse
} from '../../../../../queries/updateProjectHostingCommission.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useUpdateProjectHostingCommission } from '../../../../../hooks/useUpdateProjectHostingCommission';

interface UpdateProjectHostingCommissionFormData {
  hostingCommission: string;
}

interface UpdateProjectHostingCommissionFormOptions {
  hostingCommission: ProjectHostingCommission;
  uuid: ProjectUUID;
}

function useUpdateProjectHostingCommissionForm({
  hostingCommission,
  uuid
}: UpdateProjectHostingCommissionFormOptions) {
  const { handleSubmitReactHookForm, register, setValue } =
    useReactHookForm<UpdateProjectHostingCommissionFormData>({
      defaultValues: {
        hostingCommission: hostingCommission.toString()
      },
      isModalForm: true
    });

  const handleInitUpdateProjectHostingCommissionForm = useCallback(
    () =>
      setValue(
        ProjectFields.HOSTING_COMMISSION as 'hostingCommission',
        hostingCommission.toString()
      ),
    [hostingCommission, setValue]
  );

  const {
    updateProjectHostingCommission,
    updateProjectHostingCommissionLoading,
    updateProjectHostingCommissionErrorMessage,
    updateProjectHostingCommissionReset
  } = useUpdateProjectHostingCommission<UpdateProjectHostingCommissionQueryResponse>(
    {
      query: UPDATE_PROJECT_HOSTING_COMMISSION_QUERY
    }
  );

  return {
    updateProjectHostingCommission,
    updateProjectHostingCommissionLoading,
    updateProjectHostingCommissionErrorMessage,
    updateProjectHostingCommissionReset,
    handleInitUpdateProjectHostingCommissionForm,
    handleUpdateProjectHostingCommission: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateProjectHostingCommissionFormData) =>
        updateProjectHostingCommission({
          uuid,
          hostingCommission: toNumber(data.hostingCommission)
        })
    }),
    registerHostingCommission: register(ProjectFields.HOSTING_COMMISSION)
  };
}

export default useUpdateProjectHostingCommissionForm;
