import { Currencies } from '../../../../../../../types';

import { BillFields } from '../../../../../billsTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { createBillFormSchema } from './useCreateBillForm.schema';
import { CreateBillFormData } from '../../CreateBillForm.types';

const defaultProjectBillValues: CreateBillFormData = {
  account: 'payoneer',
  allocableUser: 'client',
  buttons: 'card',
  currency: Currencies.USD
};

const defaultTaskBillValues: CreateBillFormData = {
  account: 'task',
  allocableUser: 'client',
  buttons: 'card',
  currency: Currencies.USD
};

interface UseCreateBillFormOptions {
  type: 'project' | 'task';
}

function useCreateBillForm({ type }: UseCreateBillFormOptions) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch
  } = useReactHookForm<CreateBillFormData>({
    defaultValues:
      type === 'project' ? defaultProjectBillValues : defaultTaskBillValues,
    isModalForm: true,
    schema: createBillFormSchema
  });

  return {
    validationErrors: {
      amountValidationError: errors?.amount?.message,
      bodyValidationError: errors?.body?.message,
      currencyValidationError: errors?.currency?.message,
      accountValidationError: errors?.account?.message,
      allocableUserValidationError: errors?.allocableUser?.message
    },
    control,
    resetCreateBillForm: resetForm,
    handleSubmitReactHookForm,
    registerFields: {
      registerAmount: register(BillFields.AMOUNT),
      registerCurrency: register(BillFields.CURRENCY),
      registerAccount: register(BillFields.ACCOUNT),
      registerAllocableUser: register(BillFields.ALLOCABLE_USER),
      registerBody: register(BillFields.BODY),
      registerTransactionTagId: register(BillFields.TRANSACTION_TAG_ID)
    },
    watchAccount: watch(BillFields.ACCOUNT)
  };
}

export default useCreateBillForm;
