import React from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import { UserNanoID } from '../../../../../../../../users/usersTypes';

import { DropdownHelper } from '../../../../../../../../../helpers/dropdowns/DropdownHelper';
import { NextPureLinkHelper } from '../../../../../../../../../helpers/links/NextPureLinkHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';

import { UserPath } from '../../../../../../../../users/UserPath';

import { words } from '../../../../../../../../../locales/keys';

interface ItemIndexSidebarClientDropdownMenuProps {
  userNanoId: UserNanoID;
}

function ItemIndexSidebarClientDropdownMenu({
  userNanoId
}: ItemIndexSidebarClientDropdownMenuProps) {
  return (
    <DropdownHelper
      dropdownPlacement={PopoverPlacement.LEFT_END}
      icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
    >
      <NextPureLinkHelper
        href={UserPath.userProfile(userNanoId)}
        className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full"
        i18nText={words.viewProfile}
      />
      <NextPureLinkHelper
        href={UserPath.userProfileRequirements(userNanoId)}
        className="flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full"
        i18nText={words.viewCR}
      />
    </DropdownHelper>
  );
}

export default ItemIndexSidebarClientDropdownMenu;
