import {
  TaskPrepayment,
  TaskGqlError,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  TaskNanoID
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateTaskPrepaymentOptions {
  query: UpdateTaskGqlQuery;
}

export interface UpdateTaskPrepaymentResponse<UpdateTaskPrepaymentRecordType> {
  updateTaskPrepayment: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: UpdateTaskPrepaymentRecordType;
    errors: UpdateTaskPrepaymentError;
  };
}

export interface UpdateTaskPrepaymentInput {
  uuid: TaskUUID;
  prepayment: TaskPrepayment;
}

export interface UpdateTaskPrepaymentError {
  prepayment: TaskGqlError;
  fullMessages: TaskGqlError;
}

const action = 'updateTaskPrepayment';

const scope = 'task';

const pluralScope = 'tasks';

function useUpdateTaskPrepayment<UpdateTaskPrepaymentRecordType>({
  query
}: UpdateTaskPrepaymentOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateTaskPrepaymentInput,
    UpdateTaskPrepaymentResponse<UpdateTaskPrepaymentRecordType>,
    UpdateTaskPrepaymentError,
    UpdateTaskPrepaymentRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateTaskPrepaymentData: updateQueryData,
    updateTaskPrepaymentError: updateQueryError,
    updateTaskPrepaymentLoading: updateQueryLoading,
    updateTaskPrepaymentErrorMessage: updateQueryErrorMessage,
    updateTaskPrepayment: updateQuery,
    updateTaskPrepaymentReset: updateQueryReset
  };
}

export default useUpdateTaskPrepayment;
