import React from 'react';
import cl from 'classnames';
import camelCase from 'lodash/camelCase';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TaskStatusOptions, TaskUUID } from '../../../tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateTaskStatusForm } from '../../forms/UpdateTaskStatusForm/hooks/useUpdateTaskStatusForm';

import { UpdateTaskStatusForm } from '../../forms/UpdateTaskStatusForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { statusColor } from '../../../../../utils/statusColor';

import { TasksPermissions } from '../../../tasksConstants';

const UPDATE_TASK_STATUS_FORM = 'update-task-status-form';

interface UpdateTaskStatusModalButtonProps {
  status: TaskStatusOptions;
  uuid: TaskUUID;
  className?: string;
  icon?: IconsEnum;
  iconClassName?: string;
}

function UpdateTaskStatusModalButton({
  status,
  uuid,
  className,
  icon,
  iconClassName
}: UpdateTaskStatusModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    control,
    updateTaskStatusLoading,
    handleUpdateTaskStatus,
    handleInitUpdateTaskStatusForm,
    updateTaskStatusErrorMessage,
    updateTaskStatusReset
  } = useUpdateTaskStatusForm({ status, uuid });

  return (
    <FormModalButton
      className={
        className ||
        cl(
          'inline-flex leading-6 px-2.5 rounded-full text-xs whitespace-nowrap font-medium',
          status
            ? statusColor(status)
            : 'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700 dark:bg-opacity-50'
        )
      }
      disabled={
        !currentUser.hasPermissions(TasksPermissions.CHANGE_TASK_STATUS)
      }
      form={UPDATE_TASK_STATUS_FORM}
      i18nTitle="models.statuses.set"
      i18nText={`models.statuses.types.${camelCase(status)}`}
      i18nSubmitText="words.save"
      isLoading={updateTaskStatusLoading}
      icon={icon}
      iconClassName={iconClassName}
      onClose={updateTaskStatusReset}
      onSubmit={handleUpdateTaskStatus}
    >
      <UpdateTaskStatusForm
        control={control}
        form={UPDATE_TASK_STATUS_FORM}
        initUpdateTaskStatusForm={handleInitUpdateTaskStatusForm}
        isLoading={updateTaskStatusLoading}
      />

      <div className="px-4">
        <AlertMessage message={updateTaskStatusErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateTaskStatusModalButton;
