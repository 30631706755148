import React, { Fragment, memo } from 'react';

import { UserAvatarLink } from '../UserAvatarLink';

import { UserRoleAvatarUser } from './UserRoleAvatar.types';

interface UserRoleAvatarProps {
  user: UserRoleAvatarUser;
}

function UserRoleAvatar({ user }: UserRoleAvatarProps) {
  return (
    <Fragment>
      <div className="flex-shrink-0">
        <UserAvatarLink avatarType="main" user={user} />
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
          {user.fullName}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {user.financeRoleName}
        </p>
      </div>
    </Fragment>
  );
}

export default memo<UserRoleAvatarProps>(UserRoleAvatar);
