import { gql } from 'graphql-request';

import {
  ProjectID,
  ProjectImplementationDate,
  ProjectNanoID,
  ProjectUUID
} from '../projectsTypes';

export interface UpdateProjectImplementationDateQueryResponse {
  id: ProjectID;
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  implementationDate: ProjectImplementationDate;
}

export const UPDATE_PROJECT_IMPLEMENTATION_DATE_QUERY = gql`
  mutation UpdateProjectImplementationDate(
    $uuid: ID!
    $implementationDate: DateTime!
  ) {
    updateProjectImplementationDate(
      input: { uuid: $uuid, implementationDate: $implementationDate }
    ) {
      errors {
        implementationDate
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        implementationDate
      }
    }
  }
`;
