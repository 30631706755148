import { useCallback } from 'react';

import {
  ProjectImplementationDate,
  ProjectFields,
  ProjectUUID
} from '../../../../../projectsTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateProjectImplementationDate } from '../../../../../hooks/useUpdateProjectImplementationDate';
import { useUpdateProjectImplementationDateValidationRules } from '../useUpdateProjectImplementationDateValidationRules';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  UpdateProjectImplementationDateQueryResponse,
  UPDATE_PROJECT_IMPLEMENTATION_DATE_QUERY
} from '../../../../../queries/updateProjectImplementationDate.query';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';
import { getEndOfDay } from '../../../../../../../utils/getEndOfDay';

import { ProjectsPermissions } from '../../../../../projectsConstants';

interface UpdateProjectImplementationDateFormData {
  implementationDate?: ProjectImplementationDate;
}

interface UpdateProjectImplementationDateFormOptions {
  implementationDate: ProjectImplementationDate;
  uuid: ProjectUUID;
}

function useUpdateProjectImplementationDateForm({
  implementationDate,
  uuid
}: UpdateProjectImplementationDateFormOptions) {
  const currentUser = useCurrentUser();

  const { handleSubmitReactHookForm, isDirty, register, resetForm, errors } =
    useReactHookForm<UpdateProjectImplementationDateFormData>({
      defaultValues: {
        implementationDate: currentUser.hasPermissions(
          ProjectsPermissions.CHANGE_PROJECT_IMPLEMENTATION_TIME
        )
          ? getEndOfDay(implementationDate)
          : dateFnsConvert.toDate(implementationDate)
      },
      isModalForm: true
    });

  const { implementationDateRules } =
    useUpdateProjectImplementationDateValidationRules();

  const handleInitUpdateProjectImplementationDateForm = useCallback(() => {
    resetForm({
      implementationDate: currentUser.hasPermissions(
        ProjectsPermissions.CHANGE_PROJECT_IMPLEMENTATION_TIME
      )
        ? getEndOfDay(implementationDate)
        : dateFnsConvert.toDate(implementationDate)
    });
  }, [currentUser, implementationDate, resetForm]);

  const {
    updateProjectImplementationDate,
    updateProjectImplementationDateLoading,
    updateProjectImplementationDateErrorMessage,
    updateProjectImplementationDateReset
  } = useUpdateProjectImplementationDate<UpdateProjectImplementationDateQueryResponse>(
    {
      query: UPDATE_PROJECT_IMPLEMENTATION_DATE_QUERY
    }
  );

  return {
    updateProjectImplementationDate,
    updateProjectImplementationDateLoading,
    updateProjectImplementationDateErrorMessage,
    updateProjectImplementationDateReset,
    handleInitUpdateProjectImplementationDateForm,
    implementationDateValidationError: errors?.implementationDate?.message,
    handleUpdateProjectImplementationDate: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateProjectImplementationDateFormData) =>
        updateProjectImplementationDate({
          uuid,
          implementationDate: dateFnsConvert.toDateTimeWithTimezone(
            currentUser.hasPermissions(
              ProjectsPermissions.CHANGE_PROJECT_IMPLEMENTATION_TIME
            )
              ? data.implementationDate
              : getEndOfDay(data.implementationDate)
          )
        })
    }),
    registerProjectImplementationDate: register(
      ProjectFields.IMPLEMENTATION_DATE,
      implementationDateRules
    ),
    isDirty
  };
}

export default useUpdateProjectImplementationDateForm;
