import { ID } from '../../../../types';
import {
  ProjectGqlError,
  ProjectNanoID,
  ProjectUUID,
  UpdateProjectGqlStatus
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { ProjectCache } from '../../ProjectCache';

interface UpdateProjectTagIdsOptions {
  query: string;
}

export interface UpdateProjectTagIdsResponse<UpdateProjectTagIdsRecordType> {
  updateProjectTagIds: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: UpdateProjectTagIdsRecordType;
    errors: UpdateProjectTagIdsError;
  };
}

export interface UpdateProjectTagIdsInput {
  uuid: ProjectUUID;
  tagIds: ID[];
}

export interface UpdateProjectTagIdsError {
  fullMessages: ProjectGqlError;
}

const action = 'updateProjectTagIds';
const scope = ProjectCache.showCacheKey();
const pluralScope = ProjectCache.indexCacheKey();

function useUpdateProjectTagIds<UpdateProjectTagIdsRecordType>({
  query
}: UpdateProjectTagIdsOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    UpdateProjectTagIdsInput,
    UpdateProjectTagIdsResponse<UpdateProjectTagIdsRecordType>,
    UpdateProjectTagIdsError,
    UpdateProjectTagIdsRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateProjectTagIds: updateQuery,
    updateProjectTagIdsData: updateQueryData,
    updateProjectTagIdsError: updateQueryError,
    updateProjectTagIdsErrorMessage: updateQueryErrorMessage,
    updateProjectTagIdsLoading: updateQueryLoading,
    updateProjectTagIdsReset: updateQueryReset
  };
}

export default useUpdateProjectTagIds;
