import { dateFnsConvert } from '../dateFnsConvert';

function getEndOfDay(date: Date | string): string {
  const currentDate = new Date(date);
  currentDate.setHours(23);
  currentDate.setMinutes(59);
  currentDate.setSeconds(59);

  return dateFnsConvert.toDateTimeLocal(currentDate.toISOString());
}

export default getEndOfDay;
