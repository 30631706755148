import { useCallback, useMemo } from 'react';
import map from 'lodash/map';

import { MultiSelectDataType } from '../../../../../../../helpers/MultiSelect/types';
import { TagID, TagLocalizedName } from '../../../../../../tags/tagsTypes';
import { ProjectUUID } from '../../../../../projectsTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateProjectTagIds } from '../../../../../hooks/useUpdateProjectTagIds';

import {
  UPDATE_PROJECT_TAG_IDS_QUERY,
  UpdateProjectTagIdsQueryResponse
} from '../../../../../queries/updateProjectTagIds.query';

export interface UpdateProjectTagIdFormData {
  tagIds: MultiSelectDataType[];
}

interface UpdateProjectTagIdsFormOptions {
  selectedTags: {
    id: TagID;
    localizedName: TagLocalizedName;
  }[];
  uuid: ProjectUUID;
}

function useUpdateProjectTagIdsForm({
  selectedTags,
  uuid
}: UpdateProjectTagIdsFormOptions) {
  const selectedTagsData = useMemo<MultiSelectDataType[]>(() => {
    return map(selectedTags, (tag) => ({
      value: tag.id,
      label: tag.localizedName
    }));
  }, [selectedTags]);

  const { handleSubmitReactHookForm, isDirty, resetForm, control } =
    useReactHookForm<UpdateProjectTagIdFormData>({
      defaultValues: {
        tagIds: selectedTagsData
      },
      isModalForm: true
    });

  const handleInitUpdateProjectTagIdForm = useCallback(
    () => resetForm({ tagIds: selectedTagsData }),
    [selectedTagsData, resetForm]
  );

  const {
    updateProjectTagIds,
    updateProjectTagIdsErrorMessage,
    updateProjectTagIdsLoading,
    updateProjectTagIdsReset
  } = useUpdateProjectTagIds<UpdateProjectTagIdsQueryResponse>({
    query: UPDATE_PROJECT_TAG_IDS_QUERY
  });

  return {
    updateProjectTagIds,
    updateProjectTagIdsErrorMessage,
    updateProjectTagIdsLoading,
    updateProjectTagIdsReset,
    handleInitUpdateProjectTagIdForm,
    handleUpdateProjectTagId: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateProjectTagIdFormData) =>
        updateProjectTagIds({ uuid, tagIds: data?.tagIds?.map((t) => t.value) })
    }),
    control,
    isDirty
  };
}

export default useUpdateProjectTagIdsForm;
