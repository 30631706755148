import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { TaskFields } from '../../../tasksTypes';
import { CreateTaskFormData } from './CreateTaskForm.types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
import {
  DropzoneField,
  DropzoneFieldRequiredProps
} from '../../../../../helpers/FormFields/DropzoneField';
import { SelectField } from '../../../../../helpers/FormFields/SelectField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import {
  attachmentsKeys,
  formsFields,
  tasksKeys,
  filesKeys
} from '../../../../../locales/keys';
import { TasksPermissions } from '../../../tasksConstants';

const selectFileFormatData = [
  { value: 'png', i18nLabel: filesKeys.png },
  { value: 'jpg', i18nLabel: filesKeys.jpg },
  { value: 'tiff', i18nLabel: filesKeys.tiff }
];

interface CreateTaskFormProps {
  form: string;
  isLoading?: IsLoading;
  registerName: RegisterFormFieldType<HTMLInputElement>;
  registerDescription: RegisterFormFieldType<HTMLTextAreaElement>;
  registerAmount: RegisterFormFieldType<HTMLInputElement>;
  registerImplementationDate: RegisterFormFieldType<HTMLInputElement>;
  registerWidth: RegisterFormFieldType<HTMLInputElement>;
  registerHeight: RegisterFormFieldType<HTMLInputElement>;
  registerDpi: RegisterFormFieldType<HTMLInputElement>;
  nameValidationError: ErrorMessage;
  implementationDateValidationError: ErrorMessage;
  amountValidationError: ErrorMessage;
}

function CreateTaskForm({
  form,
  control,
  isLoading,
  registerName,
  registerDescription,
  registerAmount,
  registerImplementationDate,
  registerWidth,
  registerHeight,
  registerDpi,
  nameValidationError,
  implementationDateValidationError,
  amountValidationError
}: CreateTaskFormProps & DropzoneFieldRequiredProps<CreateTaskFormData>) {
  const currentUser = useCurrentUser();

  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                name={registerName.name}
                ref={registerName.ref}
                onChange={registerName.onChange}
                error={nameValidationError}
                i18nLabel={tasksKeys.name}
                inputWrapperClassName="mt-1 relative"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                disabled={isLoading}
                type="text"
              />
            </div>
          </div>

          <CheckPermissions action={TasksPermissions.CREATE_TASK_DESCRIPTION}>
            <div className="flex gap-3 flex-wrap">
              <div className="w-full sm:w-auto sm:flex-1">
                <TextAreaField
                  name={registerDescription.name}
                  ref={registerDescription.ref}
                  onChange={registerDescription.onChange}
                  i18nLabel={formsFields.description}
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  disabled={isLoading}
                  rows={5}
                />
              </div>
            </div>
          </CheckPermissions>

          <div className="flex gap-3 flex-wrap">
            <CheckPermissions action={TasksPermissions.CREATE_TASK_AMOUNT}>
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  name={registerAmount.name}
                  ref={registerAmount.ref}
                  onChange={registerAmount.onChange}
                  error={amountValidationError}
                  i18nLabel={formsFields.estimateCost}
                  placeholder="$"
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  inputClassName="basic-input number-appearance-none"
                  showErrorIcon={false}
                  disabled={isLoading}
                  type="number"
                />
              </div>
            </CheckPermissions>
            <CheckPermissions
              action={TasksPermissions.CREATE_TASK_IMPLEMENTATION_DATE}
            >
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  name={registerImplementationDate.name}
                  ref={registerImplementationDate.ref}
                  onChange={registerImplementationDate.onChange}
                  error={implementationDateValidationError}
                  i18nLabel={formsFields.implementationDate}
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  showErrorIcon={false}
                  disabled={isLoading}
                  type={
                    currentUser.hasPermissions(
                      TasksPermissions.CREATE_TASK_IMPLEMENTATION_TIME
                    )
                      ? 'datetime-local'
                      : 'date'
                  }
                />
              </div>
            </CheckPermissions>
          </div>

          <div className="flex gap-3 flex-wrap">
            <CheckPermissions action={TasksPermissions.CREATE_TASK_WIDTH}>
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  name={registerWidth.name}
                  ref={registerWidth.ref}
                  onChange={registerWidth.onChange}
                  i18nLabel={formsFields.width}
                  placeholder="px"
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  inputClassName="basic-input number-appearance-none"
                  showErrorIcon={false}
                  disabled={isLoading}
                  type="number"
                />
              </div>
            </CheckPermissions>
            <CheckPermissions action={TasksPermissions.CREATE_TASK_HEIGHT}>
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  name={registerHeight.name}
                  ref={registerHeight.ref}
                  onChange={registerHeight.onChange}
                  i18nLabel={formsFields.height}
                  placeholder="px"
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  inputClassName="basic-input number-appearance-none"
                  showErrorIcon={false}
                  disabled={isLoading}
                  type="number"
                />
              </div>
            </CheckPermissions>
          </div>

          <div className="flex gap-3 flex-wrap">
            <CheckPermissions action={TasksPermissions.CREATE_TASK_DPI}>
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  name={registerDpi.name}
                  ref={registerDpi.ref}
                  onChange={registerDpi.onChange}
                  i18nLabel={formsFields.dpi}
                  placeholder="dpi"
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  inputClassName="basic-input number-appearance-none"
                  showErrorIcon={false}
                  disabled={isLoading}
                  type="number"
                />
              </div>
            </CheckPermissions>

            <CheckPermissions
              action={TasksPermissions.CREATE_TASK_FILE_EXTENSION}
            >
              <div className="w-full sm:w-auto sm:flex-1">
                <SelectField
                  control={control}
                  disabled={isLoading}
                  i18nLabel={formsFields.fileFormat}
                  name={TaskFields.FILE_EXTENSION}
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  options={selectFileFormatData}
                  i18nPlaceholder={filesKeys.selectFileFormat}
                />
              </div>
            </CheckPermissions>
          </div>
        </div>
      </div>

      <CheckPermissions
        action={TasksPermissions.CREATE_TASK_FILE_ATTACHMENT_IDS}
      >
        <div className="p-4">
          <DropzoneField
            control={control}
            name={TaskFields.FILE_ATTACHMENT_IDS}
            type="fileAttachments"
            labelFor="create-task-add-files"
            i18nLabel={attachmentsKeys.plural}
            disabled={isLoading}
          />
        </div>
      </CheckPermissions>
    </Form>
  );
}

export default CreateTaskForm;
