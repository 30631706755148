import { gql } from 'graphql-request';

import { ProjectCommission, ProjectUUID } from '../projectsTypes';

export interface UpdateProjectCommissionQueryResponse {
  uuid: ProjectUUID;
  commission: ProjectCommission;
}

export const UPDATE_PROJECT_COMMISSION_QUERY = gql`
  mutation UpdateProjectCommission($uuid: ID!, $commission: Float!) {
    updateProjectCommission(input: { uuid: $uuid, commission: $commission }) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        commission
      }
      errors {
        commission
        fullMessages
      }
    }
  }
`;
