import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TaskStatusOptions, TaskUUID } from '../../../../../tasksTypes';

import { useUpdateTaskStatus } from '../../../../../hooks/useUpdateTaskStatus';

import {
  UPDATE_TASK_STATUS_QUERY,
  UpdateTaskStatusQueryResponse
} from '../../../../../queries/updateTaskStatus.query';
import { raiseError } from '../../../../../../../utils/raiseError';

export interface UpdateTaskStatusFormData {
  status: TaskStatusOptions;
}

interface UpdateTaskStatusFormOptions {
  status: TaskStatusOptions;
  uuid: TaskUUID;
}

function useUpdateTaskStatusForm({
  status,
  uuid
}: UpdateTaskStatusFormOptions) {
  const { handleSubmit, setError, setValue, control } =
    useForm<UpdateTaskStatusFormData>({
      defaultValues: {
        status
      }
    });

  const handleInitUpdateTaskStatusForm = useCallback(
    () => setValue('status', status),
    [status, setValue]
  );

  const {
    updateTaskStatus,
    updateTaskStatusReset,
    updateTaskStatusErrorMessage,
    updateTaskStatusLoading
  } = useUpdateTaskStatus<UpdateTaskStatusQueryResponse>({
    query: UPDATE_TASK_STATUS_QUERY
  });

  return {
    updateTaskStatus,
    updateTaskStatusReset,
    updateTaskStatusErrorMessage,
    updateTaskStatusLoading,
    handleInitUpdateTaskStatusForm,
    handleUpdateTaskStatus: handleSubmit(
      async (data: UpdateTaskStatusFormData) =>
        updateTaskStatus({ uuid, status: data?.status }).catch((reason) => {
          setError('status', {
            type: 'server',
            message: reason?.status?.join(', ')
          });
          raiseError('Server validation error');
        })
    ),
    control
  };
}

export default useUpdateTaskStatusForm;
