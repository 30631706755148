import React, { useEffect } from 'react';
import { Control } from 'react-hook-form';

import { IsLoading } from '../../../../../types';

import { Form } from '../../../../../helpers/Form';
import { SelectField } from '../../../../../helpers/FormFields/SelectField';

import { tasksStatuses } from '../../../tasksConstants';
import { UpdateTaskStatusFormData } from './hooks/useUpdateTaskStatusForm';

interface UpdateTaskStatusFormProps {
  control: Control<UpdateTaskStatusFormData>;
  form: string;
  initUpdateTaskStatusForm: () => void;
  isLoading?: IsLoading;
}

function UpdateTaskStatusForm({
  control,
  form,
  initUpdateTaskStatusForm,
  isLoading
}: UpdateTaskStatusFormProps) {
  useEffect(() => initUpdateTaskStatusForm(), [initUpdateTaskStatusForm]);

  return (
    <Form className="flex-1 overflow-y-auto px-2 text-sm" id={form}>
      <div className="p-4">
        <SelectField
          control={control}
          disabled={isLoading}
          labelClassName="font-light uppercase"
          name="status"
          options={tasksStatuses}
        />
      </div>
    </Form>
  );
}

export default UpdateTaskStatusForm;
