import isValid from 'date-fns/isValid';

import { PermissionAction } from '../../../../permissions/permissionsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { getEndOfDay } from '../../../../../utils/getEndOfDay';

import { formsErrors } from '../../../../../locales/keys';

interface ItemDueDateValidationRulesOptions {
  cantBeBlank?: boolean;
  action: PermissionAction;
}

function useItemDueDateValidationRules({
  cantBeBlank = true,
  action
}: ItemDueDateValidationRulesOptions) {
  const currentUser = useCurrentUser();

  return {
    validate: {
      isValid: (value: string) => {
        if (!value) {
          return cantBeBlank ? formsErrors.dueDate.cantBeBlank : true;
        }

        const newDate = new Date(
          currentUser.hasPermissions(action) ? value : getEndOfDay(value)
        );

        if (!isValid(newDate)) {
          return formsErrors.dueDate.invalid;
        }

        return newDate > new Date() || formsErrors.dueDate.inFuture;
      }
    }
  };
}

export default useItemDueDateValidationRules;
