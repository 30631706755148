import { I18nText, IsDisabled } from '../../../../../types';

import { TaskAmount, TaskUUID } from '../../../tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUpdateTaskAmountForm } from '../../forms/UpdateTaskAmountForm/hooks/useUpdateTaskAmountForm';

import { UpdateTaskAmountForm } from '../../forms/UpdateTaskAmountForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';

import { formsFields, words } from '../../../../../locales/keys';
import { TasksPermissions } from '../../../tasksConstants';

interface UpdateTaskAmountModalButtonProps {
  amount: TaskAmount;
  disabled?: IsDisabled;
  i18nPlaceholder?: I18nText;
  i18nTitle?: I18nText;
  uuid: TaskUUID;
}

const UPDATE_AMOUNT_FORM = 'update-task-amount-form';

function UpdateTaskAmountModalButton({
  amount,
  disabled,
  i18nPlaceholder,
  i18nTitle,
  uuid
}: UpdateTaskAmountModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    handleInitUpdateTaskAmountForm,
    handleUpdateTaskAmount,
    registerAmount,
    updateTaskAmountErrorMessage,
    updateTaskAmountLoading,
    updateTaskAmountReset
  } = useUpdateTaskAmountForm({
    amount,
    uuid
  });

  return (
    <FormModalButton
      buttonChildren={<MoneyHelper value={amount} />}
      className="text-lg font-semibold underline"
      disabled={
        disabled ||
        !currentUser.hasPermissions(TasksPermissions.CHANGE_TASK_AMOUNT)
      }
      form={UPDATE_AMOUNT_FORM}
      i18nSubmitText={words.save}
      i18nTitle={i18nTitle || formsFields.estimateCost}
      isLoading={updateTaskAmountLoading}
      onOpen={handleInitUpdateTaskAmountForm}
      onClose={updateTaskAmountReset}
      onSubmit={handleUpdateTaskAmount}
    >
      <UpdateTaskAmountForm
        form={UPDATE_AMOUNT_FORM}
        i18nPlaceholder={i18nPlaceholder || formsFields.estimateCost}
        isLoading={updateTaskAmountLoading}
        registerAmount={registerAmount}
      />

      <div className="px-4">
        <AlertMessage message={updateTaskAmountErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default UpdateTaskAmountModalButton;
