import { gql } from 'graphql-request';

import { ProjectHostingCommission, ProjectUUID } from '../projectsTypes';

export interface UpdateProjectHostingCommissionQueryResponse {
  uuid: ProjectUUID;
  hostingCommission: ProjectHostingCommission;
}

export const UPDATE_PROJECT_HOSTING_COMMISSION_QUERY = gql`
  mutation UpdateProjectHostingCommission(
    $uuid: ID!
    $hostingCommission: Float!
  ) {
    updateProjectHostingCommission(
      input: { uuid: $uuid, hostingCommission: $hostingCommission }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        hostingCommission
      }
      errors {
        hostingCommission
        fullMessages
      }
    }
  }
`;
