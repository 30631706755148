import { useCallback } from 'react';
import toNumber from 'lodash/toNumber';

import {
  ProjectCreditCommission,
  ProjectFields,
  ProjectUUID
} from '../../../../../projectsTypes';

import {
  UPDATE_PROJECT_CREDIT_COMMISSION_QUERY,
  UpdateProjectCreditCommissionQueryResponse
} from '../../../../../queries/updateProjectCreditCommission.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { useUpdateProjectCreditCommission } from '../../../../../hooks/useUpdateProjectCreditCommission';

interface UpdateProjectCreditCommissionFormData {
  creditCommission: string;
}

interface UpdateProjectCreditCommissionFormOptions {
  creditCommission: ProjectCreditCommission;
  uuid: ProjectUUID;
}

function useUpdateProjectCreditCommissionForm({
  creditCommission,
  uuid
}: UpdateProjectCreditCommissionFormOptions) {
  const { handleSubmitReactHookForm, register, setValue } =
    useReactHookForm<UpdateProjectCreditCommissionFormData>({
      defaultValues: {
        creditCommission: creditCommission.toString()
      },
      isModalForm: true
    });

  const handleInitUpdateProjectCreditCommissionForm = useCallback(
    () =>
      setValue(
        ProjectFields.CREDIT_COMMISSION as 'creditCommission',
        creditCommission.toString()
      ),
    [creditCommission, setValue]
  );

  const {
    updateProjectCreditCommission,
    updateProjectCreditCommissionLoading,
    updateProjectCreditCommissionErrorMessage,
    updateProjectCreditCommissionReset
  } = useUpdateProjectCreditCommission<UpdateProjectCreditCommissionQueryResponse>(
    {
      query: UPDATE_PROJECT_CREDIT_COMMISSION_QUERY
    }
  );

  return {
    updateProjectCreditCommission,
    updateProjectCreditCommissionLoading,
    updateProjectCreditCommissionErrorMessage,
    updateProjectCreditCommissionReset,
    handleInitUpdateProjectCreditCommissionForm,
    handleUpdateProjectCreditCommission: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateProjectCreditCommissionFormData) =>
        updateProjectCreditCommission({
          uuid,
          creditCommission: toNumber(data.creditCommission)
        })
    }),
    registerCreditCommission: register(ProjectFields.CREDIT_COMMISSION)
  };
}

export default useUpdateProjectCreditCommissionForm;
