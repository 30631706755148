import {
  ProjectCreditCommission,
  ProjectGqlError,
  ProjectNanoID,
  ProjectUUID,
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus
} from '../../projectsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateProjectCreditCommissionOptions {
  query: UpdateProjectGqlQuery;
}

export interface UpdateProjectCreditCommissionResponse<
  UpdateProjectCreditCommissionRecordType
> {
  updateProjectCreditCommission: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: UpdateProjectCreditCommissionRecordType;
    errors: UpdateProjectCreditCommissionError;
  };
}

export interface UpdateProjectCreditCommissionInput {
  uuid: ProjectUUID;
  creditCommission: ProjectCreditCommission;
}

export interface UpdateProjectCreditCommissionError {
  creditCommission: ProjectGqlError;
  fullMessages: ProjectGqlError;
}

const action = 'updateProjectCreditCommission';

const scope = 'project';

const pluralScope = 'projects';

function useUpdateProjectCreditCommission<
  UpdateProjectCreditCommissionRecordType
>({ query }: UpdateProjectCreditCommissionOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateProjectCreditCommissionInput,
    UpdateProjectCreditCommissionResponse<UpdateProjectCreditCommissionRecordType>,
    UpdateProjectCreditCommissionError,
    UpdateProjectCreditCommissionRecordType
  >({ action, pluralScope, scope, query });

  return {
    updateProjectCreditCommissionData: updateQueryData,
    updateProjectCreditCommissionError: updateQueryError,
    updateProjectCreditCommissionLoading: updateQueryLoading,
    updateProjectCreditCommissionErrorMessage: updateQueryErrorMessage,
    updateProjectCreditCommission: updateQuery,
    updateProjectCreditCommissionReset: updateQueryReset
  };
}

export default useUpdateProjectCreditCommission;
