import { gql } from 'graphql-request';

import { TaskAmount, TaskNanoID, TaskUUID } from '../tasksTypes';

export interface UpdateTaskAmountQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  amount: TaskAmount;
}

export const UPDATE_TASK_AMOUNT_QUERY = gql`
  mutation UpdateTaskAmount($uuid: ID!, $amount: Float!) {
    updateTaskAmount(input: { uuid: $uuid, amount: $amount }) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        amount
      }
      errors {
        amount
        fullMessages
      }
    }
  }
`;
