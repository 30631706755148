import { gql } from 'graphql-request';

export const CREATE_TASK_WITH_PROJECT_NANO_ID_QUERY = gql`
  mutation CreateTaskWithProjectNanoId(
    $projectNanoId: NanoId!
    $name: String!
    $description: String
    $implementationDate: DateTime
    $amount: Float
    $width: Integer
    $height: Integer
    $dpi: Float
    $fileExtension: String
    $fileAttachmentIds: [ID!]
  ) {
    createTaskWithProjectNanoId(
      input: {
        projectNanoId: $projectNanoId
        name: $name
        description: $description
        implementationDate: $implementationDate
        amount: $amount
        width: $width
        height: $height
        dpi: $dpi
        fileExtension: $fileExtension
        fileAttachmentIds: $fileAttachmentIds
      }
    ) {
      status
      recordUuid
      recordNanoId
      record {
        id
        uuid
        nanoId
      }
      errors {
        projectId
        name
        description
        implementationDate
        amount
        width
        height
        dpi
        fileExtension
        fileAttachmentIds
        fullMessages
      }
    }
  }
`;
