import { RefObject } from 'react';
import useResizeObserver from 'use-resize-observer';

interface useExpandableDescriptionProps {
  ref: RefObject<HTMLDivElement>;
}

function useExpandableDescription({ ref }: useExpandableDescriptionProps) {
  const { height } = useResizeObserver<HTMLDivElement>({
    ref
  });

  return { showExpandingButton: height < ref?.current?.scrollHeight };
}

export default useExpandableDescription;
