import { useCallback, useMemo } from 'react';
import map from 'lodash/map';

import { MultiSelectDataType } from '../../../../../../../helpers/MultiSelect/types';
import { TagID, TagLocalizedName } from '../../../../../../tags/tagsTypes';
import { TaskUUID } from '../../../../../tasksTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useUpdateTaskTagIds } from '../../../../../hooks/useUpdateTaskTagIds';

import {
  UPDATE_TASK_TAG_IDS_QUERY,
  UpdateTaskTagIdsQueryResponse
} from '../../../../../queries/updateTaskTagIds.query';

export interface UpdateTaskTagIdFormData {
  tagIds: MultiSelectDataType[];
}

interface UpdateTaskTagIdsFormOptions {
  selectedTags: {
    id: TagID;
    localizedName: TagLocalizedName;
  }[];
  uuid: TaskUUID;
}

function useUpdateTaskTagIdsForm({
  selectedTags,
  uuid
}: UpdateTaskTagIdsFormOptions) {
  const selectedTagsData = useMemo<MultiSelectDataType[]>(() => {
    return map(selectedTags, (tag) => ({
      value: tag.id,
      label: tag.localizedName
    }));
  }, [selectedTags]);

  const { handleSubmitReactHookForm, isDirty, resetForm, control } =
    useReactHookForm<UpdateTaskTagIdFormData>({
      defaultValues: {
        tagIds: selectedTagsData
      },
      isModalForm: true
    });

  const handleInitUpdateTaskTagIdForm = useCallback(
    () => resetForm({ tagIds: selectedTagsData }),
    [selectedTagsData, resetForm]
  );

  const {
    updateTaskTagIds,
    updateTaskTagIdsErrorMessage,
    updateTaskTagIdsLoading,
    updateTaskTagIdsReset
  } = useUpdateTaskTagIds<UpdateTaskTagIdsQueryResponse>({
    query: UPDATE_TASK_TAG_IDS_QUERY
  });

  return {
    updateTaskTagIds,
    updateTaskTagIdsErrorMessage,
    updateTaskTagIdsLoading,
    updateTaskTagIdsReset,
    handleInitUpdateTaskTagIdForm,
    handleUpdateTaskTagId: handleSubmitReactHookForm({
      onSubmit: async (data: UpdateTaskTagIdFormData) =>
        updateTaskTagIds({ uuid, tagIds: data?.tagIds?.map((t) => t.value) })
    }),
    control,
    isDirty
  };
}

export default useUpdateTaskTagIdsForm;
